import { SWRConfiguration } from "swr";
import useDataTable, { DataTableOptions } from "../../useDataTable";
import { roleAxios, roleUrls } from "./roleService";
import { RoleType } from "./useRoleQuery";

export type RolesRes = {
  data: RoleType[];
  paging: {
    totalData: number;
    totalPage: number;
  };
};

type RolesQueryParams = {
  search?: string;
};

const useRolesQuery = (
  dataTableConfig?: DataTableOptions<RolesQueryParams>,
  swrConfig?: SWRConfiguration,
) =>
  useDataTable<RolesRes, RolesQueryParams>(
    {
      url: roleUrls.list,
    },
    roleAxios,
    dataTableConfig,
    swrConfig,
  );

export default useRolesQuery;
