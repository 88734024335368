import {
  DrawerBody,
  DrawerCard,
  DrawerHeader,
  DrawerHeaderButton,
  DrawerInfo,
} from "../../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../../components/Drawer/withRouteDrawer";
import useThemeQuery from "../queries/useThemeQuery";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import EditIcon from "../../../components/Icons/Stockholm/Interface/EditIcon";
import NA from "../../../components/NoAnswer/NA";
import { Chip } from "@mui/material";

type ThemesDetailProps = {
  url: string;
} & WithRouteDrawerProps<{ id: string }>;

const ThemesDetail = ({ onClose, url, match }: ThemesDetailProps): JSX.Element => {
  const { params: { id } = { id: undefined } } = match ?? {};
  const { data, isValidating } = useThemeQuery(id);

  return (
    <DrawerCard>
      <DrawerHeader
        title="Detail Product"
        onClose={onClose}
        loading={isValidating}
        tools={
          <>
            <Link to={`${url}/update/${id}`}>
              <DrawerHeaderButton
                icon={<EditIcon size="5" />}
                label="Edit"
                className="btn-light-primary"
              />
            </Link>
          </>
        }
      />
      <DrawerBody>
        {data ? (
          <>
            <DrawerInfo label="Name">{data.name || <NA />}</DrawerInfo>
            <DrawerInfo label="Partner">{data.partner || <NA />}</DrawerInfo>
            <DrawerInfo label="Is Pro">
              {data.isPro ? (
                <Chip label="Pro" size="small" color="primary" />
              ) : (
                <Chip label="No" size="small" />
              )}
            </DrawerInfo>
            <DrawerInfo label="Scheme">{data.mode || <NA />}</DrawerInfo>
            <DrawerInfo label="Logo">
              {data.logo ? (
                <a href={data.logo} target="_blank" rel="noopener noreferrer">
                  <img className="img-thumbnail mw-150px" src={data.logo} alt="" />
                </a>
              ) : (
                <NA />
              )}
            </DrawerInfo>
            <DrawerInfo label="Thumbnail">
              {data.thumbnail ? (
                <a href={data.thumbnail} target="_blank" rel="noopener noreferrer">
                  <img className="img-thumbnail mw-150px" src={data.thumbnail} alt="" />
                </a>
              ) : (
                <NA />
              )}
            </DrawerInfo>
            <DrawerInfo label="Created at">
              {data.created_at ? dayjs(data.created_at).format("DD MMM YYYY HH:mm") : <NA />}
            </DrawerInfo>
            <DrawerInfo label="Last Updated at">
              {data.updated_at ? dayjs(data.updated_at).format("DD MMM YYYY HH:mm") : <NA />}
            </DrawerInfo>
          </>
        ) : null}
      </DrawerBody>
    </DrawerCard>
  );
};

export default withRouteDrawer(ThemesDetail);
