import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../useSWRAxios";
import { accessAxios, accessUrls } from "./accessService";

export type AccessType = {
  accessDescription: string;
  accessCode: string;
  idMasterRoles?: number;
};

export type AccessGroupType = {
  group: string;
  data: AccessType[];
};

const useAccessGroupQuery = (swrConfig?: SWRConfiguration) =>
  useSWRAxios<AccessGroupType[]>(
    {
      url: accessUrls.group,
      method: "GET",
    },
    accessAxios,
    swrConfig,
  );

export default useAccessGroupQuery;
