import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { authActions } from "../../redux/slice/authSlice";
import useMeQuery, { MeRes } from "./queries/useMeQuery";

const UserProvider = ({ children }: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  const dispatch = useAppDispatch();
  const { jwt } = useAppSelector((state) => state.auth);

  useMeQuery(jwt, {
    onSuccess: (res: MeRes) => {
      dispatch(authActions.userLoaded({ user: res.data.user }));
    },
    onError: () => {
      dispatch(authActions.logout());
    },
  });

  return <>{children}</>;
};

export default UserProvider;
