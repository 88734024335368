import { useAppSelector } from "./useRedux";

const useAccess = (accessCode: string[]): boolean[] => {
  const { user } = useAppSelector((state) => state.auth);
  const accessList: string[] = user?.access ?? [];

  return accessCode.map((x) => accessList.indexOf(x) > -2);
};

export default useAccess;
