import { Redirect, Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";

const NoAuthRoute = ({ children, ...props }: RouteProps): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);
  return user ? <Redirect to="/" /> : <Route {...props}>{children}</Route>;
};

export default NoAuthRoute;
