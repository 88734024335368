import { Redirect, Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import { useAppSelector } from "../../hooks/useRedux";
// import useSystemQuery from "../../hooks/queries/system/useSystemQuery";
// import { useEffect } from "react";
// import { configActions } from "../../redux/slice/configSlice";

const AuthRoute = ({ children, ...props }: RouteProps): JSX.Element | null => {
  // const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  // const { data: systemData } = useSystemQuery();

  // useEffect(() => {
  //   if (systemData) dispatch(configActions.setSystemInfo(systemData));
  // }, [systemData]);

  return user ? <Route {...props}>{children}</Route> : <Redirect to="/login" />;
};

export default AuthRoute;
