import { useAsyncFn } from "react-use";
import Button from "../../components/Button/Button";
import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../components/Drawer/withRouteDrawer";
import { CreateRoleResponse, roleAxios, roleUrls } from "../../hooks/queries/roles/roleService";
import { toast } from "react-toastify";
import RoleForm, { RoleFormSubmittedValues } from "./RoleForm";
import { useHistory } from "react-router";

const formId = "createRoleForm";

const RoleCreate = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const { push } = useHistory();
  const [state, submit] = useAsyncFn(
    async (values: RoleFormSubmittedValues) => {
      const response = await roleAxios.post<CreateRoleResponse>(roleUrls.create, values);
      toast.success("Role Created");
      push(`/user-management/roles/detail/${response.data.idMasterRoles}`);
      return response;
    },
    [push],
  );

  return (
    <DrawerCard>
      <DrawerHeader title="Create Role" onClose={onClose} />
      <DrawerBody>
        <RoleForm formId={formId} onSubmit={submit} defaultValues={{ roleName: "", access: [] }} />
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

export default withRouteDrawer(RoleCreate);
