import { useCallback } from "react";
import Limit from "./Limit";
import SortableTh from "./SortableTh";
import Pagination from "./Pagination";
import Progress from "./Progress/Progress";
import { TableConfig } from "../../hooks/useDataTable";
import clsx from "clsx";

export type HeadColumn = {
  /** Table Head label */
  label?: string | JSX.Element;
  /** sort key retrieved from th click */
  key?: string;
} & React.ComponentPropsWithoutRef<"th">;

type TableProps = {
  /** total data found */
  total?: number;
  /** table column list */
  columns?: HeadColumn[];
  /** is loading state */
  loading?: boolean;
  /** table configs */
  config: TableConfig;
  /** config setter */
  setConfig?: (config: TableConfig) => void;
} & React.ComponentPropsWithoutRef<"table">;

const DataTable = ({
  total = 0,
  columns = [],
  loading = false,
  config,
  setConfig,
  children,
  className,
  ...props
}: TableProps): JSX.Element => {
  const handleLimit = useCallback(
    (limit: TableConfig["limit"]) => {
      setConfig?.({
        ...config,
        // /** reset to first page */
        // page: 1,
        limit,
      });
    },
    [config, setConfig],
  );

  const handleChangePage = useCallback(
    (page: TableConfig["page"]) => {
      setConfig?.({
        ...config,
        page,
      });
    },
    [config, setConfig],
  );

  const handleSort = useCallback(
    (sort: TableConfig["sort"]) => {
      setConfig?.({
        ...config,
        sort,
      });
    },
    [config, setConfig],
  );

  return (
    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
      <div className="table-responsive">
        <table
          {...props}
          className={clsx("table align-middle table-row-dashed fs-6 gy-5 dataTable", className)}
        >
          <thead>
            <tr className="fs-7 fw-bolder gs-0 text-muted text-start text-uppercase">
              {columns.map((col, i) => (
                <SortableTh key={i} config={config} onSort={handleSort} col={col} />
              ))}
            </tr>
            <tr className="border-0">
              <td colSpan={columns.length} className="p-0">
                <div className="position-relative">
                  <Progress in={loading} />
                </div>
              </td>
            </tr>
          </thead>
          {children}
        </table>
      </div>
      <div className="row">
        <div className="col-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          <Limit config={config} onLimit={handleLimit} total={total} />
        </div>
        <div className="col-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
          <Pagination config={config} onChangePage={handleChangePage} total={total} />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
