import Aside from "./Aside";
import Header from "./Header";
import Title from "./Title";

type MainLayoutProps = {
  children: React.ReactElement;
};

const MainLayout = ({ children }: MainLayoutProps): JSX.Element => {
  return (
    <div className="page d-flex flex-row flex-column-fluid">
      <Aside />
      {/* begin::Wrapper */}
      <main className="wrapper d-flex flex-column flex-row-fluid">
        {/* begin::Header */}
        <Header />
        {/* end::Header */}
        {/* begin::Content */}
        <div className="content d-flex flex-column flex-column-fluid">
          {/* begin::Container */}
          <div className="container-fluid">
            <div id="titleMobileContainer">
              <Title forMobile />
            </div>
            {children}
          </div>
        </div>
        {/* end::Content */}
      </main>
      {/* end::Wrapper */}
    </div>
  );
};

export default MainLayout;
