import clsx from "clsx";

const DrawerBody = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div className={clsx("card-body overflow-auto", className)} {...props}>
      {children}
    </div>
  );
};

export default DrawerBody;
