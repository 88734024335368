import { useCallback, useState } from "react";
import { useDebounce } from "react-use";

export type TableConfig = {
  sort?: {
    key: string;
    isAsc: boolean;
  };
  limit: number;
  page: number;
};

export type PreparedConfig = {
  limit?: number;
  page?: number;
  sort?: `${string}|asc` | `${string}|desc`;
};

export type UseDataTableConfigProps<P> = {
  defaultConfig?: Partial<TableConfig>;
  defaultParams?: P;
  debounceTimeout?: number;
};

const prepareToQueryParams = (config: TableConfig): PreparedConfig => ({
  limit: config.limit,
  page: config.page,
  sort: config.sort?.key ? `${config.sort.key}|${config.sort.isAsc ? "asc" : "desc"}` : undefined,
});

const useDataTableConfig = <Params>({
  defaultConfig,
  defaultParams,
  debounceTimeout = 300,
}: UseDataTableConfigProps<Params> = {}) => {
  const [config, setConfig] = useState<TableConfig>({
    sort: defaultConfig?.sort ? { ...defaultConfig?.sort } : undefined,
    limit: defaultConfig?.limit ?? 10,
    page: defaultConfig?.page ?? 1,
  });
  const [params, changeParams] = useState<Params>(defaultParams ?? ({} as Params));
  const [debouncedVariables, setDebouncedVariables] = useState<PreparedConfig & Params>({
    ...prepareToQueryParams(config),
    ...params,
  });

  const setParams = useCallback((key: keyof Params, value) => {
    changeParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setConfig((s) => ({
      ...s,
      page: 1,
    }));
  }, []);

  useDebounce(
    () => {
      setDebouncedVariables({
        ...prepareToQueryParams(config),
        ...params,
      });
    },
    debounceTimeout,
    [config, params],
  );

  const getQuery = useCallback(
    (page: number) => ({ ...debouncedVariables, page }),
    [debouncedVariables],
  );

  return {
    config,
    setConfig,
    params,
    setParams,
    query: debouncedVariables,
    getQuery,
  };
};

export default useDataTableConfig;
