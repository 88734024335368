import { useMemo } from "react";
import { animated, useTransition } from "react-spring";
import type { SpringConfig } from "react-spring";

interface SlideProps extends React.ComponentPropsWithoutRef<"div"> {
  in: boolean;
  slide?: "up" | "down" | "left" | "right";
  config?: SpringConfig;
}

const range = 100;

const Slide = ({
  in: inProp,
  slide = "right",
  children,
  config,
  ...props
}: SlideProps): JSX.Element => {
  const slideFromAndLeave = useMemo(() => {
    switch (slide) {
      case "up":
        return { transform: `translate3d(0, ${range}%, 0)` };
      case "down":
        return { transform: `translate3d(0, -${range}%, 0)` };
      case "left":
        return { transform: `translate3d(${range}%, 0, 0)` };
      case "right":
        return { transform: `translate3d(-${range}%, 0, 0)` };
      default:
        return {};
    }
  }, [slide]);

  const slideEnter = useMemo(() => {
    switch (slide) {
      case "up":
      case "down":
        return { transform: `translate3d(0, 0%, 0)` };
      case "left":
      case "right":
        return { transform: `translate3d(0%, 0, 0)` };
      default:
        return {};
    }
  }, [slide]);

  const transitions = useTransition(inProp, {
    config,
    from: { ...slideFromAndLeave },
    enter: { ...slideEnter },
    leave: { ...slideFromAndLeave },
    reverse: inProp,
  });

  return transitions(
    (styles, show) =>
      show && (
        <animated.div style={styles} {...props}>
          {children}
        </animated.div>
      ),
  );
};

export default Slide;
