import { Link } from "react-router-dom";
import MenuIcon from "../../Icons/Stockholm/Text/MenuIcon";
import { useAppDispatch } from "../../../hooks/useRedux";
import Logo from "../../Icons/Logo";
import { configActions } from "../../../redux/slice/configSlice";
import Title from "./Title";
import UserMenu from "./UserMenu";

const Header = (): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div id="kt_header" className="header align-items-stretch">
      {/* begin::Container */}
      <div className="container-fluid d-flex align-items-stretch justify-content-between">
        {/* begin::Aside mobile toggle */}
        <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
          <button
            className="btn btn-icon btn-active-light-primary"
            onClick={() => dispatch(configActions.toggleAsideOpen(true))}
          >
            <MenuIcon size="2x" className="mt-1" />
          </button>
        </div>
        {/* end::Aside mobile toggle */}
        {/* begin::Mobile logo */}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <Link to="/" className="d-lg-none">
            <Logo size={90} />
          </Link>
        </div>
        {/* end::Mobile logo */}
        {/* begin::Wrapper */}
        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          {/* begin::Navbar */}
          <div className="d-flex align-items-stretch" id="titleDesktopContainer">
            <Title />
          </div>
          {/* end::Navbar */}
          {/* begin::Topbar */}
          <div className="d-flex align-items-stretch flex-shrink-0">
            {/* begin::Toolbar wrapper */}
            <div className="d-flex align-items-stretch flex-shrink-0">
              <UserMenu />
            </div>
            {/* end::Toolbar wrapper */}
          </div>
          {/* end::Topbar */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export default Header;
