import { Helmet } from "react-helmet-async";

const DashboardPage = (): JSX.Element => {
  return (
    <div className="card">
      <Helmet defaultTitle="Dashboard | Biobio" titleTemplate="%s - Dashboard | Biobio" />
      <div className="card-body">
        <h4 className="mb-0">Welcome to Biobio Admin App</h4>
      </div>
    </div>
  );
};

export default DashboardPage;
