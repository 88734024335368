import { useMemo } from "react";
import { TableConfig } from "../../hooks/useDataTable";

type ShowingProps = {
  /** table configs */
  config: TableConfig;
  /** total data found */
  total: number;
};

const Showing = ({ config: { limit, page }, total }: ShowingProps): JSX.Element => {
  const from = useMemo(() => {
    const res = (page - 1) * limit + 1;
    return total > 0 ? res : 0;
  }, [page, limit, total]);

  const to = useMemo(() => {
    let res = page * limit;
    if (res > total) res = total;
    return res;
  }, [page, limit, total]);

  return (
    <span className="me-2">
      Showing {from} - {to} of {total}
    </span>
  );
};

export default Showing;
