import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import SearchIcon from "../../../components/Icons/Stockholm/General/SearchIcon";
import PlusIcon from "../../../components/Icons/Stockholm/Navigation/PlusIcon";
import { Tbody, Tr, Td } from "../../../components/Table";
import useThemesQuery from "../queries/useThemesQuery";
import usePages from "../../../hooks/usePages";
import ThemesCreate from "./ThemesCreate";
import ThemesUpdate from "./ThemesUpdate";
import ThemesDetail from "./ThemesDetail";
import useDataTableConfig, { PreparedConfig } from "../../../hooks/useDataTableConfig";
import { ThemesQueryParams } from "../types/types";

const ThemesList = (): JSX.Element => {
  const { path, url } = useRouteMatch();
  const { config, setConfig, setParams, query, getQuery } = useDataTableConfig<ThemesQueryParams>({
    defaultConfig: {
      sort: { key: "created_at", isAsc: false },
    },
  });
  const { data, isValidating, mutate } = useThemesQuery(query);
  const count = data?.totalData ?? 0;

  const pages = usePages(count, config.page, config.limit, {
    includeFirstAndLast: true,
  });

  return (
    <div className="card">
      <Helmet title="Theme List" />
      <ThemesCreate path="/create" onClose={mutate} />
      <ThemesDetail path="/detail/:id" onClose={mutate} url={url} />
      <ThemesUpdate path="/update/:id" onClose={mutate} />
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <SearchIcon size="1" className="position-absolute ms-6" />
            <input
              type="text"
              className="form-control form-control-sm form-control-solid w-250px ps-14"
              placeholder="Search"
              onChange={(e) => setParams("search", e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
            <Link to={path + "/create"} className="btn btn-primary">
              <PlusIcon size="2" />
              Create Theme
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <DataTable
          columns={[
            { label: "No." },
            { label: "Name" },
            { label: "Partner" },
            { label: "Scheme" },
            { label: "Created at", key: "createdAt" },
          ]}
          loading={isValidating}
          total={data?.totalData ?? 0}
          config={config}
          setConfig={setConfig}
        >
          {pages.map((page) => (
            <Body key={page} query={getQuery(page)} active={page === config.page} page={page} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

type BodyProps = {
  query: PreparedConfig;
  active: boolean;
  page: number;
} & React.ComponentPropsWithoutRef<"tbody">;

const Body = ({ query, active, page }: BodyProps): JSX.Element => {
  const { url } = useRouteMatch();
  const { data } = useThemesQuery(query);
  const startNo = useMemo(() => (page - 1) * (query.limit ?? 0) + 1, [query, page]);

  return (
    <Tbody className={clsx("text-gray-600 fw-bold", !active && "d-none")}>
      {data?.data.map((d, i) => (
        <Tr key={d.id}>
          <Td>{startNo + i}</Td>
          <Td>
            <Link to={`${url}/detail/${d.id}`} className="text-gray-800 text-hover-primary">
              {d.name}
            </Link>
          </Td>
          <Td>{d.partner}</Td>
          <Td>{d.mode}</Td>
          <Td>{dayjs(d.created_at).format("DD MMM YYYY HH:mm")}</Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default ThemesList;
