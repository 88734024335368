import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { UserType, LoggedInUserAdditionalDataType } from "../../modules/User/queries/useUserQuery";

type CombinedUserType = UserType & LoggedInUserAdditionalDataType;

type AuthSlice = {
  /** user data */
  user?: CombinedUserType;
  /** loading user state */
  isLoadingUser: boolean;
  /** token for authenticating user */
  jwt?: string;
  /** token for refreshing expired `jwt` */
  refreshJwt?: string;
};

const initialState: AuthSlice = {
  user: undefined,
  isLoadingUser: false,
  jwt: undefined,
  refreshJwt: undefined,
};
const sliceName = "authSlice";

const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setToken: (state, { payload: { token } }: PayloadAction<{ token: string }>) => {
      state.jwt = token;
    },
    logout: () => initialState,
    loadingUser: (state) => {
      state.isLoadingUser = true;
    },
    userLoaded: (state, { payload: { user } }: PayloadAction<{ user: CombinedUserType }>) => {
      state.isLoadingUser = false;
      state.user = user;
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = persistReducer(
  {
    key: sliceName,
    version: 1,
    storage,
    whitelist: ["jwt", "refreshJwt"],
  },
  authSlice.reducer,
);
