import { Helmet } from "react-helmet-async";
import Carousel from "../../components/Carousel/Carousel";
import ForgotPasswordModal from "./ForgotPasswordModal";
import LoginForm from "./LoginForm";

const LoginPage = (): JSX.Element => {
  return (
    <div className="row g-0 min-vh-100">
      <Helmet title="Login" />
      <div className="col-6 d-none d-md-block">
        <div className="w-50 vh-100 position-fixed start-0 top-0">
          <Carousel
            images={[
              "https://images.pexels.com/photos/62689/pexels-photo-62689.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
              "https://images.pexels.com/photos/4016596/pexels-photo-4016596.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
              "https://images.pexels.com/photos/351265/pexels-photo-351265.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
              "https://images.pexels.com/photos/924675/pexels-photo-924675.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            ]}
          />
        </div>
      </div>
      <div className="col-12 col-md-6 px-5 py-7 d-flex align-items-center justify-content-center bg-white">
        <div className="d-flex flex-column">
          <div className="mw-325px mb-20 pb-20">
            <h1 className="fs-2x mb-4 fw-boldest">
              Get All Your
              <br />
              Links
              <br />
              Tracked in One Place!
            </h1>
            <div className="mb-8">
              by&nbsp;
              <a className="text-primary" href="https://biobio.id/">
                biobio
              </a>
            </div>
            <LoginForm />
            <ForgotPasswordModal path="/forgot-password" />
          </div>
          <div className="mw-400px">
            <p className="fw-boldest">
              Use <span className="text-primary">biobio</span> now and claim your Free NFC Dot!
            </p>
            <p>
              Visit&nbsp;
              <span className="text-success">
                our Head Office at Satrio Tower Lantai 16, Jakarta, ID
              </span>
              &nbsp;for further information. Thank you for your support!
            </p>
            <div>© 2021 Biobio | PT Rintis Inovasi Indonesia</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
