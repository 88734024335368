import ReactDOM from "react-dom";
import Backdrop from "../Backdrop/Backdrop";
import { useKey } from "react-use";
import Fade from "../Transitions/Fade/Fade";
import clsx from "clsx";
import CloseIcon from "../Icons/Stockholm/Navigation/CloseIcon";
import { config } from "react-spring";

interface ModalProps extends React.ComponentPropsWithoutRef<"div"> {
  in: boolean;
  onClose?: () => void;
}
const bodyEl = document.body;

const Modal = ({ in: inProp, onClose, children, className, ...props }: ModalProps): JSX.Element => {
  useKey("Escape", () => inProp && onClose && onClose(), undefined, [inProp, onClose]);

  return ReactDOM.createPortal(
    <>
      <Fade
        {...props}
        in={inProp}
        className={clsx("modal d-block", className)}
        config={config.stiff}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              type="button"
              className="btn btn-sm btn-active-light-primary btn-icon float-end position-absolute end-0 top-0 mt-5 me-5"
              onClick={onClose}
            >
              <CloseIcon size="2" />
            </button>
            {children}
          </div>
        </div>
      </Fade>
      <Backdrop in={inProp} onClick={onClose} className="modal-overlay" />
    </>,
    bodyEl,
  );
};

export default Modal;
