import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";

type MuiSwitchProps<T extends FieldValues> = UseControllerProps<T> &
  SwitchProps & {
    /** Form Control Label */
    label: FormControlLabelProps["label"];
    /** Form Helper Text */
    helperText?: React.ReactNode;
    formControlLabelProps?: Omit<FormControlLabelProps, "label" | "control">;
  };

const MuiSwitch = <T extends FieldValues>({
  control,
  name,
  helperText,
  label,
  defaultValue,
  shouldUnregister,
  formControlLabelProps,
  ...rest
}: MuiSwitchProps<T>): JSX.Element => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    shouldUnregister,
  });

  const checked = typeof value === "boolean" ? value : false;

  return (
    <FormControl error={Boolean(error?.message)} variant="standard">
      <FormControlLabel
        {...formControlLabelProps}
        label={label}
        control={<Switch {...rest} {...inputProps} checked={checked} inputRef={ref} />}
      />
      <FormHelperText>{error?.message ?? helperText}</FormHelperText>
    </FormControl>
  );
};

export default MuiSwitch;
