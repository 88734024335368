import { Helmet } from "react-helmet-async";
import { Switch, useRouteMatch } from "react-router-dom";
import TitledRoute from "../../../components/Route/TitledRoute";
import ProductList from "./ProductList";

const ProductsRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet defaultTitle="Products | Biobio" titleTemplate="%s - Products | Biobio" />
      <Switch>
        <TitledRoute title="Product List" path={path}>
          <ProductList />
        </TitledRoute>
      </Switch>
    </>
  );
};

export default ProductsRoutes;
