import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import { productAxios, productService } from "./productService";

export type ProductType = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  type: string;
  color: string;
  picture: string;
};

const useProductQuery = (id?: number | string, swrConfig?: SWRConfiguration) =>
  useSWRAxios<ProductType>(
    id
      ? {
          url: productService.detail(id),
          method: "GET",
        }
      : null,
    productAxios,
    swrConfig,
  );

export default useProductQuery;
