import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { FieldError, useController, UseControllerProps } from "react-hook-form";
import { useMemo } from "react";

export type MuiAutocompleteProps<
  FieldsType,
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = UseControllerProps<FieldsType> &
  Omit<
    AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "openOnFocus" | "defaultValue" | "placeholder"
  > & {
    /** Text Field props */
    textFieldProps?: TextFieldProps;
  };

const MuiAutocomplete = <
  FieldsType,
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  control,
  name,
  onChange,
  textFieldProps,
  defaultValue,
  shouldUnregister,
  ...rest
}: MuiAutocompleteProps<FieldsType, Option, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  const {
    field: { ref, value, onChange: fieldOnChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    shouldUnregister,
  });

  const errorMessage = useMemo(() => {
    if (Array.isArray(error)) {
      const arrayOfError = error as FieldError[];
      return arrayOfError.map(
        (err, key) =>
          err?.message && (
            <span className="d-block" key={key}>
              {err.message}
            </span>
          ),
      );
    } else if (typeof error === "object") {
      return error?.message;
    }
    return undefined;
  }, [error]);

  return (
    <Autocomplete
      {...rest}
      value={value as AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>["value"]}
      onChange={(e, v, ...props) => {
        fieldOnChange(v);
        if (onChange) onChange(e, v, ...props);
      }}
      onClose={onBlur}
      openOnFocus
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...textFieldProps}
          {...params}
          name={name}
          InputProps={{
            ...InputProps,
            ...textFieldProps?.InputProps,
            endAdornment: (
              <>
                {textFieldProps?.InputProps?.endAdornment}
                {InputProps.endAdornment}
              </>
            ),
          }}
          inputRef={ref}
          label={textFieldProps?.label}
          error={Boolean(error)}
          helperText={errorMessage ?? textFieldProps?.helperText}
          variant="filled"
        />
      )}
    />
  );
};

export default MuiAutocomplete;
