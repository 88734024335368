type usePagesOptions = {
  /** inclde first and last page */
  includeFirstAndLast?: boolean;
  /**
   * max pages returned
   * @default 5
   */
  maxPages?: number;
};

const usePages = (
  totalItems: number,
  currentPage: number,
  pageSize: number,
  options?: usePagesOptions,
): number[] => {
  const { includeFirstAndLast = false, maxPages = 5 } = options || {};
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
  // ensure current page isn't out of range
  const validatedCurrentPage =
    currentPage < 1 ? 1 : currentPage > totalPages ? totalPages : currentPage;

  let startPage: number, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (validatedCurrentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (validatedCurrentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = validatedCurrentPage - maxPagesBeforeCurrentPage;
      endPage = validatedCurrentPage + maxPagesAfterCurrentPage;
    }
  }

  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => {
    return startPage + i;
  });

  // add first & last page
  if (includeFirstAndLast && !pages.includes(1)) pages.push(1);
  if (includeFirstAndLast && !pages.includes(totalPages)) pages.push(totalPages);

  // return object with all pager properties required by the view
  return pages;
};

export default usePages;
