import axios from "axios";
import jsonToFormData from "../../../../utils/jsonToFormData";
import { ThemesFormValues } from "../components/ThemesForm";

export const themesAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export const getThemesUrl = "/theme";
export const getThemeUrl = (id: string) => `/theme/${id}`;

export const createTheme = (body: ThemesFormValues) =>
  themesAxios.post("/theme", jsonToFormData(body));
export const updateTheme = (id: string, body: ThemesFormValues) =>
  themesAxios.post(`/theme/${id}`, jsonToFormData(body));
