import {
  DrawerBody,
  DrawerCard,
  DrawerHeader,
  DrawerHeaderButton,
  DrawerInfo,
} from "../../../../components/Drawer/DrawerCard";
import withRouteDrawer, {
  WithRouteDrawerProps,
} from "../../../../components/Drawer/withRouteDrawer";
import { useRouteMatch } from "react-router";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import EditIcon from "../../../../components/Icons/Stockholm/Interface/EditIcon";
import useUserQuery from "../../queries/useUserQuery";
import UserDetailBlockUnblockButton from "./UserDetailBlockUnblockButton";
import UserDetailConnectedNFC from "./UserDetailConnectedNFC";
import NA from "../../../../components/NoAnswer/NA";
import CopyTooltip from "../../../../components/Tooltip/CopyTooltip";

type UserDetailProps = {
  url: string;
} & WithRouteDrawerProps;

const UserDetail = ({ onClose, url }: UserDetailProps): JSX.Element => {
  const match = useRouteMatch<{ id: string }>();
  const id = match?.params.id;
  const { data, isValidating, mutate } = useUserQuery(id);

  return (
    <DrawerCard>
      <DrawerHeader
        title="Detail User"
        onClose={onClose}
        loading={isValidating}
        tools={
          data && (
            <>
              <Link to={`${url}/update/${id}`}>
                <DrawerHeaderButton
                  icon={<EditIcon size="5" />}
                  label="Edit"
                  className="btn-light-primary"
                />
              </Link>
              <UserDetailBlockUnblockButton user={data} onSuccess={mutate} />
            </>
          )
        }
      />
      <DrawerBody>
        {data ? (
          <>
            <div className="fw-bolder fs-4 mb-3">General</div>
            <DrawerInfo label="Username">
              <CopyTooltip url={`https://biobio.id/${data.username}`}>
                {data.username ?? <NA />}
              </CopyTooltip>
            </DrawerInfo>
            <DrawerInfo label="Name">{data.name ?? <NA />}</DrawerInfo>
            <DrawerInfo label="Email">{data.email ?? <NA />}</DrawerInfo>
            <DrawerInfo label="is Pro">
              {data.isPro ? (
                <span className="badge badge-primary">Yes</span>
              ) : (
                <span className="badge badge-light">No</span>
              )}
            </DrawerInfo>
            <DrawerInfo label="Last Active">
              {data.lastActive ? dayjs(data.lastActive).format("DD MMM YYYY HH:mm:ss") : <NA />} |{" "}
              {data.lastDevice ?? <NA />}
            </DrawerInfo>
            <DrawerInfo label="Created at">
              {data.created_at ? dayjs(data.created_at).format("DD MMM YYYY HH:mm:ss") : <NA />}
            </DrawerInfo>
            <DrawerInfo label="Last Updated at">
              {data.updated_at ? dayjs(data.updated_at).format("DD MMM YYYY HH:mm:ss") : <NA />}
            </DrawerInfo>
            <UserDetailConnectedNFC />
          </>
        ) : null}
      </DrawerBody>
    </DrawerCard>
  );
};

export default withRouteDrawer(UserDetail);
