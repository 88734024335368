import Button from "../../../components/Button/Button";
import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../../components/Drawer/withRouteDrawer";
import { toast } from "react-toastify";
import useAxiosAsync from "../../../hooks/useAxiosAsync";
import ThemesForm, { ThemesFormValues } from "./ThemesForm";
import useThemeQuery from "../queries/useThemeQuery";
import { updateTheme } from "../queries/themesService";

const formId = "themeUpdate";

const ThemesUpdate = ({ onClose, match }: WithRouteDrawerProps<{ id: string }>): JSX.Element => {
  const { params: { id } = { id: undefined } } = match ?? {};
  const { data, isValidating, mutate } = useThemeQuery(id);

  const [state, submit] = useAxiosAsync(
    async (values: ThemesFormValues) => {
      if (!id) return;
      try {
        const response = await updateTheme(id, values);
        toast.success("Theme Updated");
        onClose?.();
        mutate();
        return response;
      } catch (error) {
        toast.error("Failed to Update Theme");
        throw error;
      }
    },
    [id],
  );

  return (
    <DrawerCard>
      <DrawerHeader title="Update Theme" onClose={onClose} loading={isValidating} />
      <DrawerBody>
        {data ? <ThemesForm formId={formId} onSubmit={submit} theme={data} /> : null}
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

export default withRouteDrawer(ThemesUpdate);
