import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../../../components/Drawer/DrawerCard";
import withRouteDrawer, {
  WithRouteDrawerProps,
} from "../../../../components/Drawer/withRouteDrawer";
import Button from "../../../../components/Button/Button";
import MuiTextField from "../../../../components/ControlledForm/MuiTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";
import { nfcAxios, nfcUrls } from "../../queries/nfcService";
import { toast } from "react-toastify";
import ProductsAutoComplete from "../../../Products/utilities/ProductsAutoComplete";

type CreateNFCValues = {
  note: string;
  quantity: number;
  productId: number;
};

const formId = "NFCCreateBulk";

const NFCCreateBulk = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const [state, submit] = useAxiosAsync(async (values: CreateNFCValues) => {
    try {
      const response = await nfcAxios.post(nfcUrls.createBulk, values);
      toast.success("NFCs Created");
      onClose?.();
      return response;
    } catch (error) {
      toast.error("Failed to Create NFCs");
      throw error;
    }
  }, []);

  return (
    <DrawerCard>
      <DrawerHeader title="Create Bulk NFC" onClose={onClose} />
      <DrawerBody>
        <CreateNFC
          onSubmit={submit}
          defaultValues={{
            quantity: 1,
            note: "",
          }}
        />
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

const schema = yup.object().shape({
  quantity: yup.number().typeError("must be a number").min(1).required(),
  note: yup.string(),
  productId: yup.number().nullable().required(),
});

type CreateNFCProps = {
  /** form on submit */
  onSubmit: (values: CreateNFCValues) => void;
  /** form default values */
  defaultValues: Partial<CreateNFCValues>;
};

const CreateNFC = ({ defaultValues, onSubmit }: CreateNFCProps) => {
  const { handleSubmit, control, register, setValue } = useForm<CreateNFCValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const productInputs = register("productId");

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
      <MuiTextField
        control={control}
        label="Quantity"
        name="quantity"
        placeholder="e.g 150"
        defaultValue={defaultValues.quantity ?? ""}
        type="number"
      />
      <MuiTextField
        control={control}
        label="Note"
        name="note"
        placeholder="e.g For Certain Vendor"
        defaultValue={defaultValues.note ?? ""}
      />
      <ProductsAutoComplete
        placeholder="Select"
        onChange={(_e, v) => {
          if (v) setValue("productId", v.id);
        }}
        disableClearable
        textFieldProps={{
          label: "Select Product",
          onBlur: productInputs.onBlur,
          inputRef: productInputs.ref,
        }}
      />
    </form>
  );
};

export default withRouteDrawer(NFCCreateBulk);
