import { FormEvent } from "react";
import { TableConfig } from "../../hooks/useDataTable";
import Showing from "./Showing";

type LimitProps = {
  /** select limit change handler */
  onLimit: (limit: TableConfig["limit"]) => void;
  /** table configs */
  config: TableConfig;
  /** total data found */
  total: number;
};

const options = [10, 25, 50, 100, 1000];

const Limit = ({ onLimit, config, total }: LimitProps): JSX.Element => {
  const handleChange = (e: FormEvent<HTMLSelectElement>) => {
    onLimit(Number(e.currentTarget.value));
  };

  return (
    <div className="dataTables_length">
      <Showing config={config} total={total} />
      <label>
        <select
          className="form-select form-select-sm form-select-solid"
          value={config.limit}
          onChange={handleChange}
        >
          {options.map((l) => (
            <LimitOption key={l} value={l} />
          ))}
        </select>
      </label>
    </div>
  );
};

type LimitOptionProps = {
  /** limit option value */
  value: number;
};

const LimitOption = ({ value }: LimitOptionProps) => {
  return <option value={value}>{value}</option>;
};

export default Limit;
