import useProductsQuery, { ProductsRes } from "../queries/useProductsQuery";
import MuiAutocomplete, {
  MuiAutocompleteProps,
} from "../../../components/UncontrolledForm/MuiAutocomplete";

export type ProductsAutoCompleteOption = ProductsRes["data"][number];

type ProductsAutoCompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = Omit<
  MuiAutocompleteProps<ProductsAutoCompleteOption, Multiple, DisableClearable, false>,
  "options"
>;

const ProductsAutoComplete = <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
>({
  ...rest
}: ProductsAutoCompleteProps<Multiple, DisableClearable>): JSX.Element => {
  const { data, isValidating, setParams } = useProductsQuery({
    defaultConfig: {
      limit: 20,
    },
  });

  const options = data?.data ?? [];

  return (
    <MuiAutocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => option.name}
      onInputChange={(_e, newInputValue) => {
        setParams("search", newInputValue ? `name|${newInputValue}` : undefined);
      }}
      filterOptions={(x) => x}
      loading={isValidating}
      loadingText="Loading..."
      isOptionEqualToValue={(o, v) => o.id === v.id}
    />
  );
};

export default ProductsAutoComplete;
