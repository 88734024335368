import { AxiosError } from "axios";
import { DependencyList } from "react";
import { useAsyncFn } from "react-use";
import { FunctionReturningPromise, PromiseType } from "react-use/lib/misc/types";

type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: true;
      error?: AxiosError | undefined;
      value?: T;
    }
  | {
      loading: false;
      error: AxiosError;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };

type StateFromFunctionReturningPromise<T extends FunctionReturningPromise> = AsyncState<
  PromiseType<ReturnType<T>>
>;

type UseAxiosAsyncReturn<T extends FunctionReturningPromise> = [
  StateFromFunctionReturningPromise<T>,
  T,
];

const useAxiosAsync = <T extends FunctionReturningPromise>(
  fn: T,
  deps?: DependencyList,
  initialState?: StateFromFunctionReturningPromise<T>,
): UseAxiosAsyncReturn<T> => {
  const [state, run] = useAsyncFn(fn, deps, initialState);
  return [
    {
      loading: state.loading,
      error: state.error as AxiosError,
      value: state.value,
    },
    run,
  ];
};

export default useAxiosAsync;
