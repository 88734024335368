import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import { productAxios, productService } from "./productService";

export type ProductTypesType = {
  data: string[];
};

const useProductTypesQuery = (swrConfig?: SWRConfiguration) =>
  useSWRAxios<ProductTypesType>(
    {
      url: productService.getAllType,
      method: "GET",
    },
    productAxios,
    swrConfig,
  );

export default useProductTypesQuery;
