import Button from "../../../components/Button/Button";
import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../../components/Drawer/withRouteDrawer";
import { toast } from "react-toastify";
import useAxiosAsync from "../../../hooks/useAxiosAsync";
import ProductForm, { ProductFormValues } from "./ProductForm";
import { useRouteMatch } from "react-router-dom";
import useProductQuery from "../queries/useProductQuery";
import { productAxios, productService } from "../queries/productService";
import jsonToFormData from "../../../../utils/jsonToFormData";

const formId = "productUpdate";

const ProductUpdate = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const match = useRouteMatch<{ id: string }>();
  const id = match?.params.id;
  const { data, isValidating, mutate } = useProductQuery(id);

  const [state, submit] = useAxiosAsync(async (values: ProductFormValues) => {
    try {
      const body: Record<string, unknown> = {
        name: values.name,
        type: values.type,
        color: values.color,
      };
      if (values.picture?.[0]) body.picture = values.picture[0];
      const formData = jsonToFormData(body);

      const response = await productAxios.post(productService.update(id), formData);
      toast.success("Product Updated");
      onClose?.();
      mutate();
      return response;
    } catch (error) {
      toast.error("Failed to Update Product");
      throw error;
    }
  }, []);

  return (
    <DrawerCard>
      <DrawerHeader title="Update Product" onClose={onClose} loading={isValidating} />
      <DrawerBody>
        {data ? (
          <ProductForm
            formId={formId}
            onSubmit={submit}
            defaultValues={{
              name: data.name ?? "",
              type: data.type ?? "",
              color: data.color ?? "",
              picture: [],
            }}
            initialPictureSrc={data.picture}
          />
        ) : null}
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

export default withRouteDrawer(ProductUpdate);
