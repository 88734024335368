import useThemesQuery from "../queries/useThemesQuery";
import MuiAutocomplete, {
  MuiAutocompleteProps,
} from "../../../components/UncontrolledForm/MuiAutocomplete";
import useDataTableConfig from "../../../hooks/useDataTableConfig";
import type { ThemesQueryParams, ThemeType } from "../types/types";

export type ThemesAutoCompleteOption = ThemeType;

type ThemesAutoCompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = Omit<
  MuiAutocompleteProps<ThemesAutoCompleteOption, Multiple, DisableClearable, false>,
  "options"
>;

const ThemesAutoComplete = <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
>({
  ...rest
}: ThemesAutoCompleteProps<Multiple, DisableClearable>): JSX.Element => {
  const { setParams, query } = useDataTableConfig<ThemesQueryParams>({
    defaultConfig: {
      limit: 20,
    },
  });
  const { data, isValidating } = useThemesQuery(query);

  const options = data?.data ?? [];

  return (
    <MuiAutocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => option.name}
      onInputChange={(_e, newInputValue) => {
        setParams("search", newInputValue ? `name|${newInputValue}` : undefined);
      }}
      filterOptions={(x) => x}
      loading={isValidating}
      loadingText="Loading..."
      isOptionEqualToValue={(o, v) => o.id === v.id}
    />
  );
};

export default ThemesAutoComplete;
