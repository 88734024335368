import { useEffect } from "react";
import { SplashScreen } from "../../components/SplashScreen/SplashScreen";
import { useAppDispatch } from "../../hooks/useRedux";
import { authActions } from "../../redux/slice/authSlice";

const LogoutPage = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return <SplashScreen title="Clearing User data" />;
};

export default LogoutPage;
