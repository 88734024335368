import StockholmIcon, { StockholmIconProps } from "../StockholmIcon";

const EditIcon = (props: StockholmIconProps): JSX.Element => {
  return (
    <StockholmIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect opacity="0.25" x="3" y="21" width="18" height="2" rx="1" fill="#191213" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.08576 11.5L3.58579 12C3.21071 12.375 3 12.8838 3 13.4142V17C3 18.1045 3.89543 19 5 19H8.58579C9.11622 19 9.62493 18.7893 10 18.4142L10.5 17.9142L4.08576 11.5Z"
          fill="#121319"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 10.0858L11.5858 4L18 10.4142L11.9142 16.5L5.5 10.0858Z"
          fill="#121319"
        />
        <path
          opacity="0.25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1214 1.70705C16.9498 0.535476 15.0503 0.535476 13.8787 1.70705L13 2.58576L19.4142 8.99997L20.2929 8.12126C21.4645 6.94969 21.4645 5.0502 20.2929 3.87862L18.1214 1.70705Z"
          fill="#191213"
        />
      </svg>
    </StockholmIcon>
  );
};

export default EditIcon;
