import ReloadIcon from "../Icons/Stockholm/General/ReloadIcon";
import Button, { ButtonProps } from "./Button";

const RefreshButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button
      {...props}
      variant="outline-primary"
      className="btn-icon btn-icon-primary ms-3"
      icon={<ReloadIcon size="2" />}
    />
  );
};
export default RefreshButton;
