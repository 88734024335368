import { BrowserRouter as Router } from "react-router-dom";
import "./app.scss";
import AppRoutes from "./AppRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SplashScreen, SplashScreenProvider } from "./components/SplashScreen/SplashScreen";
import ToastifyProvider from "./components/Toastify/ToastifyProvider";
import AxiosProvider from "./components/Axios/AxiosProvider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./materialUITheme";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import UserProvider from "./modules/User/UserProvider";

// Filepond plugins registration
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

const App = (): JSX.Element => {
  return (
    <SplashScreenProvider>
      {/* Redux store Provider */}
      <ReduxProvider store={store}>
        {/* Axios Provider, setup any axios interceptor here */}
        <AxiosProvider>
          {/* App Meta Tag Provider */}
          <HelmetProvider>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate
              persistor={persistor}
              loading={<SplashScreen title="Persisting Last Configuration" />}
            >
              <UserProvider>
                {/* Material UI Theme Provider */}
                <ThemeProvider theme={theme}>
                  {/* React Toastify Provider */}
                  <ToastifyProvider>
                    {/* Material UI Date Picker Localization Provider */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* Default App Title */}
                      <Helmet titleTemplate="%s | Biobio" />
                      <div className="d-flex flex-column flex-root">
                        {/* React Router */}
                        <Router>
                          {/* Base application routes */}
                          <AppRoutes />
                        </Router>
                      </div>
                    </LocalizationProvider>
                  </ToastifyProvider>
                </ThemeProvider>
              </UserProvider>
            </PersistGate>
          </HelmetProvider>
        </AxiosProvider>
      </ReduxProvider>
    </SplashScreenProvider>
  );
};

export default App;
