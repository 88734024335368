import { Helmet } from "react-helmet-async";
import { Switch, useRouteMatch } from "react-router-dom";
import TitledRoute from "../../../components/Route/TitledRoute";
import UserList from "./UserList";

const UserPage = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet defaultTitle="Users | Biobio" titleTemplate="%s - Users | Biobio" />
      <Switch>
        <TitledRoute title="User List" path={path}>
          <UserList />
        </TitledRoute>
      </Switch>
    </>
  );
};

export default UserPage;
