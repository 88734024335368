import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import CheckCircleIcon from "../../components/Icons/Stockholm/General/CheckCircleIcon";
import InfoOutlineIcon from "../../components/Icons/Stockholm/Code/InfoOutlineIcon";
import MuiTextField from "../../components/ControlledForm/MuiTextField";
import useAxiosAsync from "../../hooks/useAxiosAsync";
import { InputAdornment } from "@mui/material";
import VisibleOffIcon from "../../components/Icons/Stockholm/General/VisibleOffIcon";
import VisibleIcon from "../../components/Icons/Stockholm/General/VisibleIcon";
import { useState } from "react";
import useLocationQuery from "../../hooks/useLocationQuery";
import { userAxios, userUrls } from "../../modules/User/queries/userService";

type Payload = {
  newPassword: string;
};

const schema = yup.object().shape({
  newPassword: yup.string().required("Password is required"),
});

const ResetPasswordPage = (): JSX.Element => {
  const { push } = useHistory();
  const query = useLocationQuery();
  const token = query.get("token");

  const [state, submit]: any = useAxiosAsync(
    async (payload: Payload) => {
      const data = {
        ...payload,
        authToken: token,
      };
      const response = await userAxios.post(userUrls.changePassword, data);
      setTimeout(() => {
        push("/login");
      }, 2500);
      return response;
    },
    [push],
  );

  const { control, handleSubmit } = useForm<Payload>({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  return (
    <div className={"d-flex flex-column justify-content-center align-items-center p-7 min-vh-100"}>
      <Helmet title="Reset Password" />
      <div className="mw-600px w-100 text-center px-7 py-20 bg-white rounded-3">
        <div className="fs-3 mb-7 fw-boldest">Reset Password</div>
        <form onSubmit={handleSubmit(submit)} className="mw-325px m-auto">
          <MuiTextField
            control={control}
            name="newPassword"
            label="New Password"
            placeholder="Input Password"
            fullWidth
            type={showPassword ? "text" : "password"}
            defaultValue=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    tabIndex={-1}
                    type="button"
                    className="btn-icon"
                    onClick={handleClickShowPassword}
                    icon={showPassword ? <VisibleOffIcon size="2" /> : <VisibleIcon size="2" />}
                  />
                </InputAdornment>
              ),
            }}
          />
          {!token && (
            <div className="mt-7">
              <Alert variant="danger" Icon={InfoOutlineIcon}>
                Token is missing
              </Alert>
            </div>
          )}
          {state?.error && (
            <div className="mt-7">
              <Alert variant="danger" Icon={InfoOutlineIcon}>
                {state?.error?.response?.data?.message ?? "Something went wrong"}
              </Alert>
            </div>
          )}
          <div className="mt-7">
            {state.value ? (
              <Alert Icon={CheckCircleIcon} className="text-left">
                You password has been changed, in a moment we will redirect you to login screen. If
                nothing happens,&nbsp;
                <Link to="/login" className="text-decoration-underline">
                  click here
                </Link>
              </Alert>
            ) : (
              <Button
                type="submit"
                variant="base-primary"
                loading={state.loading}
                className="btn-lg"
                disabled={!token}
              >
                Save Password
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
