import Button from "../../../components/Button/Button";
import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../../components/Drawer/withRouteDrawer";
import { toast } from "react-toastify";
import useAxiosAsync from "../../../hooks/useAxiosAsync";
import ProductForm, { ProductFormValues } from "./ProductForm";
import { productAxios, productService } from "../queries/productService";
import jsonToFormData from "../../../../utils/jsonToFormData";

const formId = "productCreate";

const ProductCreate = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const [state, submit] = useAxiosAsync(async (values: ProductFormValues) => {
    try {
      const body: Record<string, unknown> = {
        name: values.name,
        type: values.type,
        color: values.color,
      };
      if (values.picture?.[0]) body.picture = values.picture[0];
      const formData = jsonToFormData(body);

      const response = await productAxios.post(productService.create, formData);
      toast.success("Product Created");
      onClose?.();
      return response;
    } catch (error) {
      toast.error("Failed to Create Product");
      throw error;
    }
  }, []);

  return (
    <DrawerCard>
      <DrawerHeader title="Create Product" onClose={onClose} />
      <DrawerBody>
        <ProductForm
          formId={formId}
          onSubmit={submit}
          defaultValues={{
            name: "",
            type: "",
            color: "",
            picture: [],
          }}
        />
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

export default withRouteDrawer(ProductCreate);
