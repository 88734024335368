import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../../../components/Drawer/DrawerCard";
import withRouteDrawer, {
  WithRouteDrawerProps,
} from "../../../../components/Drawer/withRouteDrawer";
import { useRouteMatch, useHistory } from "react-router";
import useNFCQuery from "../../queries/useNFCQuery";
import Button from "../../../../components/Button/Button";
import MuiTextField from "../../../../components/ControlledForm/MuiTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";
import { nfcAxios, nfcUrls } from "../../queries/nfcService";
import { toast } from "react-toastify";

type NFCUpdateProps = {
  url: string;
} & WithRouteDrawerProps;

type UpdateNFCFormValues = {
  note: string;
};

const formId = "nfcUpdate";

const NFCUpdate = ({ onClose, url }: NFCUpdateProps): JSX.Element => {
  const { push } = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const id = match?.params.id;
  const { data, isValidating, mutate } = useNFCQuery(id);
  const backUrl = `${url}/detail/${id}`;

  const [state, submit] = useAxiosAsync(async (values: UpdateNFCFormValues) => {
    try {
      const response = await nfcAxios.post(nfcUrls.update(id), values);
      toast.success("NFC Updated");
      mutate();
      push(backUrl);
      return response;
    } catch (error) {
      toast.error("Failed to Update NFC");
      throw error;
    }
  }, []);

  return (
    <DrawerCard>
      <DrawerHeader title="Update NFC" onClose={onClose} loading={isValidating} backTo={backUrl} />
      <DrawerBody>
        {data ? (
          <UpdateNFCForm
            onSubmit={submit}
            defaultValues={{
              note: data.note ?? "",
            }}
          />
        ) : null}
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

const schema = yup.object().shape({
  note: yup.string(),
});

type UpdateNFCFormProps = {
  /** form on submit */
  onSubmit: (values: UpdateNFCFormValues) => void;
  /** form default values */
  defaultValues: Partial<UpdateNFCFormValues>;
};

const UpdateNFCForm = ({ defaultValues, onSubmit }: UpdateNFCFormProps) => {
  const { handleSubmit, control } = useForm<UpdateNFCFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
      <MuiTextField
        control={control}
        label="Note"
        name="note"
        placeholder="e.g For Certain Vendor"
        defaultValue={defaultValues.note ?? ""}
      />
    </form>
  );
};

export default withRouteDrawer(NFCUpdate);
