import withRouteModal from "../../components/Modal/withRouteModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import Warning2Icon from "../../components/Icons/Stockholm/Code/Warning2Icon";
import DoneCircleIcon from "../../components/Icons/Stockholm/Code/DoneCircleIcon";
import MuiTextField from "../../components/ControlledForm/MuiTextField";
import { Helmet } from "react-helmet-async";
import useAxiosAsync from "../../hooks/useAxiosAsync";
import { userAxios, userUrls } from "../../modules/User/queries/userService";

const schema = yup.object().shape({
  email: yup.string().required(),
});

type ForgotPasswordFormValues = {
  email: string;
};

const ForgotPasswordModal = () => {
  const [state, submit] = useAxiosAsync(async (payload: ForgotPasswordFormValues) => {
    const response = await userAxios.post(userUrls.forgotPassword, payload);
    return response;
  }, []);

  const { control, handleSubmit } = useForm<Partial<ForgotPasswordFormValues>>({
    resolver: yupResolver(schema),
  });

  return (
    <div className="p-4">
      <Helmet title="Forgot Password" />
      <form className="text-center m-auto mw-325px py-14" onSubmit={handleSubmit(submit)}>
        <h4 className="mb-4 fw-boldest">Reset your password</h4>
        <p className="mw-300px m-auto fs-5 mb-8">
          To login, please enter your email and we will send a link to reset your password
        </p>
        <div className="pb-3">
          <MuiTextField
            control={control}
            type="email"
            name="email"
            label="Email"
            placeholder="Enter your email"
            defaultValue=""
          />
        </div>
        {state.error && (
          <Alert variant="danger" Icon={Warning2Icon}>
            {state.error}
          </Alert>
        )}
        {state.value ? (
          <Alert Icon={DoneCircleIcon}>Email sent!</Alert>
        ) : (
          <Button type="submit" variant="base-primary" loading={state.loading} className="mt-9">
            Submit
          </Button>
        )}
      </form>
    </div>
  );
};

export default withRouteModal(ForgotPasswordModal);
