import { StockholmIconProps } from "../Icons/Stockholm/StockholmIcon";

const variant = {
  primary: "primary",
  success: "success",
  danger: "danger",
  warning: "warning",
};

type AlertProps = {
  variant?: keyof typeof variant;
  Icon?: (props: StockholmIconProps) => JSX.Element;
} & React.ComponentPropsWithoutRef<"div">;

const Alert = ({ variant = "primary", children, Icon }: AlertProps): JSX.Element => {
  return (
    <div className={`alert alert-${variant} bg-light-${variant} d-flex align-items-center`}>
      {Icon ? <Icon size="2x" color={variant} className="me-3" /> : null}
      <div className={`d-flex flex-column text-${variant}`}>{children}</div>
    </div>
  );
};

export default Alert;
