import StockholmIcon, { StockholmIconProps } from "../StockholmIcon";

const PlusIcon = (props: StockholmIconProps): JSX.Element => {
  return (
    <StockholmIcon {...props}>
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
        <rect
          fill="#000000"
          opacity="0.5"
          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
          x="4"
          y="11"
          width="16"
          height="2"
          rx="1"
        />
      </svg>
    </StockholmIcon>
  );
};

export default PlusIcon;
