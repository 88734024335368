import { Link, useRouteMatch } from "react-router-dom";
import Logo from "../../Icons/Logo";
import Collapse from "../../Transitions/Collapse/Collapse";
import { useCallback, useEffect, useRef, useState } from "react";
import ColorProfileIcon from "../../Icons/Stockholm/Design/ColorProfileIcon";
import clsx from "clsx";
import AngleDoubleLeftIcon from "../../Icons/Stockholm/Navigation/AngleDoubleLeftIcon";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import Backdrop from "../../Backdrop/Backdrop";
import { configActions } from "../../../redux/slice/configSlice";
import { animated, useSpring } from "react-spring";
import { useMeasure, useMedia } from "react-use";
import CreditCardIcon from "../../Icons/Stockholm/Shopping/CreditCardIcon";
import DashboardIcon from "../../Icons/Stockholm/Design/DashboardIcon";
import GroupIcon from "../../Icons/Stockholm/Communication/GroupIcon";
import ChipIcon from "../../Icons/Stockholm/Code/ChipIcon";
import BucketPaintIcon from "../../Icons/Stockholm/Design/BucketPaintIcon";

export type MenuType = {
  title?: string;
  icon?: JSX.Element;
  url?: string;
  urlPrefix?: string;
  menu?: ChildMenu[];
};

export type ChildMenu = {
  title?: string;
  url?: string;
  urlPrefix?: string;
  menu?: ChildMenu[];
};

export const menu: MenuType[] = [
  {
    title: "Dashboard",
    icon: <DashboardIcon size="2" />,
    url: "",
  },
  { title: "Technology" },
  {
    title: "NFCs",
    icon: <CreditCardIcon size="2" />,
    url: "/nfc",
  },
  {
    title: "Products",
    icon: <ColorProfileIcon size="2" />,
    url: "/products",
  },
  {
    title: "Themes",
    icon: <BucketPaintIcon size="2" />,
    url: "/themes",
  },
  { title: "Admin" },
  {
    title: "Users",
    icon: <GroupIcon size="2" />,
    url: "/users",
  },
  {
    title: "Roles",
    icon: <ChipIcon size="2" />,
    url: "/roles",
  },
  // {
  //   title: "User Management",
  //   icon: <ShieldProtectedIcon size="2" />,
  //   urlPrefix: "/user-management",
  //   menu: [
  //     {
  //       title: "Users",
  //       url: "/users",
  //     },
  //     {
  //       title: "Roles",
  //       url: "/roles",
  //     },
  //   ],
  // },
];
const documentBody = document.body;

const Aside = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAsideOpen, isAsideMinimized } = useAppSelector((state) => state.config);
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);

  const asideRef = useRef<HTMLDivElement>(null);

  // Minimize Aside Handler
  const toggleMinimize = useCallback(() => {
    dispatch(configActions.toggleAsideMinimize());
  }, [dispatch]);

  useEffect(() => {
    documentBody.dataset.ktAsideMinimize = isAsideMinimized ? "on" : "off";

    // Below code disable aside-hoverable when minizime button clicked
    const asideEl = asideRef.current;
    if (asideEl) {
      asideEl.classList.add("animating");
      setTimeout(() => {
        asideEl.classList.remove("animating");
      }, 300);
    }
  }, [isAsideMinimized]);

  const matchDekstopDownSize = useMedia("(max-width: 991px)");

  const closeAside = useCallback(() => {
    if (isAsideOpen) dispatch(configActions.toggleAsideOpen(false));
  }, [dispatch, isAsideOpen]);

  const { transform } = useSpring({
    transform:
      isAsideOpen || !matchDekstopDownSize ? "translate3d(0%, 0, 0)" : "translate3d(-100%, 0, 0)",
  });

  const handleMenuClick = useCallback(
    (key) => {
      setOpenedMenu((s) => (s === key ? null : key));
    },
    [setOpenedMenu],
  );

  const handleChildMatch = useCallback((key, isChildHaveDropdown) => {
    if (isChildHaveDropdown) setOpenedMenu(key);
  }, []);

  const [headerRef, { height: headerHeight }] = useMeasure<HTMLDivElement>();
  // const [footerRef, { height: footerHeight }] = useMeasure<HTMLDivElement>();

  return (
    <animated.div
      className={clsx(
        "aside aside-dark aside-hoverable",
        matchDekstopDownSize && ["drawer", "w-250px"],
      )}
      ref={asideRef}
      style={{ transform }}
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" ref={headerRef}>
        {/* begin::Logo */}
        <Link
          to="/"
          className="logo"
          onClick={() => {
            setOpenedMenu(null);
            closeAside();
          }}
        >
          <Logo white size={90} />
        </Link>
        {/* end::Logo */}
        {/* begin::Aside toggler */}
        <button
          className={clsx(
            "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
            isAsideMinimized && "active",
          )}
          onClick={toggleMinimize}
        >
          <AngleDoubleLeftIcon size="1" className="rotate-180" />
        </button>
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}
      {/* begin::Aside menu */}
      <div
        className="aside-menu flex-column-fluid"
        style={{ maxHeight: `calc(100% - ${headerHeight}px)` }}
      >
        {/* begin::Aside Menu */}
        <div className="hover-scroll-overlay-y my-5 my-lg-5">
          {/* begin::Menu */}
          <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
            {menu.map((m, key) => (
              <Menu
                key={key}
                onClick={() => handleMenuClick(typeof m.urlPrefix !== "undefined" ? key : null)}
                onMatch={(isChildHaveDropdown) => handleChildMatch(key, isChildHaveDropdown)}
                open={openedMenu === key}
                m={m}
                onLinkClick={closeAside}
              />
            ))}
          </div>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Aside menu */}
      {/* begin::Aside Drawer Backdrop */}
      <Backdrop in={isAsideOpen} onClick={closeAside} />
      {/* end::Aside Drawer Backdrop */}
    </animated.div>
  );
};

type MenuProps = {
  onClick?: () => void;
  onMatch: (isChildHaveDropdown?: string) => void;
  open: boolean;
  m: MenuType;
  onLinkClick?: () => void;
};

const Menu = ({ onClick, onMatch, m, open, onLinkClick }: MenuProps): JSX.Element => {
  const match = useRouteMatch({
    path: typeof m.url !== "undefined" ? m.url : undefined,
    exact: m?.url === "",
  });
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);

  const handleMenuClick = useCallback((key) => {
    setOpenedMenu((s) => (s === key ? null : key));
  }, []);

  const handleChildMatch = useCallback((key, isChildHaveDropdown) => {
    if (isChildHaveDropdown) setOpenedMenu(key);
    onMatch(m.urlPrefix);
  }, []);

  useEffect(() => {
    if (match) onMatch(m.urlPrefix);
  }, []);

  return typeof m.url !== "undefined" || typeof m?.urlPrefix !== "undefined" ? (
    <div
      className={clsx(
        "menu-item",
        menu && menu.length > 0 && "menu-accordion",
        open && "show",
        match && "here",
      )}
    >
      {typeof m.url !== "undefined" ? (
        <Link
          className={clsx("menu-link", match && "active")}
          to={m.url}
          onClick={() => {
            onClick?.();
            onLinkClick?.();
          }}
        >
          <span className="menu-icon">{m?.icon}</span>
          <span className="menu-title">{m?.title}</span>
        </Link>
      ) : (
        <span className="menu-link" onClick={onClick}>
          <span className="menu-icon">{m?.icon}</span>
          <span className="menu-title">{m?.title}</span>
          <span className="menu-arrow"></span>
        </span>
      )}
      {m?.menu && m?.menu.length > 0 && (
        <Collapse in={open}>
          <div
            className={clsx(
              "menu-sub menu-sub-accordion show",
              // isHere && "menu-active-bg"
            )}
          >
            {m.menu.map((childMenu, key) => (
              <ChildMenu
                key={key}
                onClick={
                  typeof childMenu.urlPrefix !== "undefined"
                    ? () => handleMenuClick(key)
                    : undefined
                }
                onMatch={(isChildHaveDropdown) => handleChildMatch(key, isChildHaveDropdown)}
                open={openedMenu === key}
                m={childMenu}
                urlPrefix={m?.urlPrefix}
                onLinkClick={onLinkClick}
              />
            ))}
          </div>
        </Collapse>
      )}
    </div>
  ) : (
    <div className="menu-item">
      <div className="menu-content pb-3">
        <span className="menu-section text-primary-light-grey text-uppercase fs-8 ls-1">
          {m?.title}
        </span>
      </div>
    </div>
  );
};

type ChildMenuProps = {
  onClick?: () => void;
  onMatch: (isChildHaveDropdown?: string) => void;
  m: ChildMenu;
  open: boolean;
  urlPrefix?: string;
  onLinkClick?: () => void;
};

const ChildMenu = ({ onClick, onMatch, m, open, urlPrefix, onLinkClick }: ChildMenuProps) => {
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);
  const combinedPrefix = `${urlPrefix}${m?.urlPrefix ?? ""}`;
  const combinedUrl = `${urlPrefix}${m?.urlPrefix ?? ""}${m?.url ?? ""}`;

  const match = useRouteMatch({
    path: typeof m.url !== "undefined" ? combinedUrl : undefined,
    exact: m?.url === "",
  });

  const handleMenuClick = useCallback((key) => {
    setOpenedMenu((s) => (s === key ? null : key));
  }, []);

  const handleChildMatch = useCallback((key, isChildHaveDropdown) => {
    if (isChildHaveDropdown) setOpenedMenu(key);
    onMatch(m.urlPrefix);
  }, []);

  useEffect(() => {
    if (match) {
      onMatch(m.urlPrefix);
    }
  }, []);

  return (
    <div
      className={clsx(
        "menu-item",
        menu && menu.length > 0 && "menu-accordion",
        open && "show",
        match && "here",
      )}
    >
      {typeof m.url !== "undefined" ? (
        <Link
          className={clsx("menu-link", match && "active")}
          to={combinedUrl}
          onClick={() => {
            onClick?.();
            onLinkClick?.();
          }}
        >
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
          <span className="menu-title">{m?.title}</span>
        </Link>
      ) : (
        <span className="menu-link" onClick={onClick}>
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
          <span className="menu-title">{m?.title}</span>
          <span className="menu-arrow"></span>
        </span>
      )}
      {m?.menu && m?.menu.length > 0 && (
        <Collapse in={open}>
          <div
            className={clsx(
              "menu-sub menu-sub-accordion show",
              // isHere && "menu-active-bg"
            )}
          >
            {m.menu.map((childMenu, key) => (
              <ChildMenu
                key={key}
                onClick={
                  typeof childMenu.urlPrefix !== "undefined"
                    ? () => handleMenuClick(key)
                    : undefined
                }
                onMatch={(isChildHaveDropdown) => handleChildMatch(key, isChildHaveDropdown)}
                open={openedMenu === key}
                m={childMenu}
                urlPrefix={combinedPrefix}
                onLinkClick={onLinkClick}
              />
            ))}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default Aside;
