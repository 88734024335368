import clsx from "clsx";
import { TableConfig } from "../../hooks/useDataTable";
import usePages from "../../hooks/usePages";
import AngleLeftIcon from "../Icons/Stockholm/Navigation/AngleLeftIcon";
import AngleRightIcon from "../Icons/Stockholm/Navigation/AngleRightIcon";
import ArrowToLeftIcon from "../Icons/Stockholm/Navigation/ArrowToLeftIcon";
import ArrowToRightIcon from "../Icons/Stockholm/Navigation/ArrowToRightIcon";

type PaginationProps = {
  /** change page handler */
  onChangePage: (page: TableConfig["page"]) => void;
  /** table configs */
  config: TableConfig;
  /** total data found */
  total: number;
};

const Pagination = ({
  onChangePage,
  config: { limit, page },
  total,
}: PaginationProps): JSX.Element | null => {
  const pages = usePages(total, page, limit);
  const totalPage = Math.ceil(total / limit);

  const viewPage = (nextValue: number) => {
    if (nextValue > 0 && nextValue <= totalPage) onChangePage(nextValue);
  };

  return totalPage > 0 ? (
    <div className="dataTables_paginate">
      <ul className="pagination">
        <PaginationItem
          onClick={viewPage}
          disabled={page <= 1}
          page={1}
          label={<ArrowToLeftIcon size="6" />}
        />
        <PaginationItem
          onClick={viewPage}
          disabled={page <= 1}
          page={page - 1}
          label={<AngleLeftIcon size="6" />}
        />
        {pages.map((p) => (
          <PaginationItem key={p} onClick={viewPage} active={page === p} page={p} label={p} />
        ))}
        <PaginationItem
          onClick={viewPage}
          disabled={page >= totalPage}
          page={page + 1}
          label={<AngleRightIcon size="6" />}
        />
        <PaginationItem
          onClick={viewPage}
          disabled={page >= totalPage}
          page={totalPage}
          label={<ArrowToRightIcon size="6" />}
        />
      </ul>
    </div>
  ) : null;
};

type PaginationItemProps = {
  /** disabled button */
  disabled?: boolean;
  /** set active class */
  active?: boolean;
  /** button on click handler */
  onClick: (nextValue: number) => void;
  /** button label */
  label: string | number | JSX.Element;
  /** class name */
  className?: string;
  /** page number returned on click */
  page: number;
};

const PaginationItem = ({
  disabled,
  active,
  onClick,
  label,
  className,
  page,
}: PaginationItemProps) => {
  return (
    <li className={clsx("page-item", className, disabled && "disabled", active && "active")}>
      <button onClick={() => onClick(page)} className="page-link">
        {label}
      </button>
    </li>
  );
};

export default Pagination;
