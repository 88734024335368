import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import type { ThemeType } from "../types/types";
import { getThemeUrl, themesAxios } from "./themesService";

const useThemeQuery = (id?: string, swrConfig?: SWRConfiguration) =>
  useSWRAxios<ThemeType>(
    id
      ? {
          url: getThemeUrl(id),
          method: "GET",
        }
      : null,
    themesAxios,
    swrConfig,
  );

export default useThemeQuery;
