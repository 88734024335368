import { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import useSWR, { SWRConfiguration } from "swr";

export const useSWRAxios = <Data>(
  key: AxiosRequestConfig | null,
  fetcher: AxiosInstance,
  swrConfig?: SWRConfiguration,
) =>
  useSWR<Data, AxiosError, AxiosRequestConfig | null>(
    key,
    (request: AxiosRequestConfig) => fetcher(request).then((res) => res.data),
    swrConfig,
  );
