import clsx from "clsx";

const DrawerForm = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"form">): JSX.Element => {
  return (
    <form className={clsx("card w-100", className)} noValidate {...props}>
      {children}
    </form>
  );
};

export default DrawerForm;
