import { SWRConfiguration } from "swr";
import { PreparedConfig } from "../../../hooks/useDataTableConfig";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import type { ThemesQueryParams, ThemesRes } from "../types/types";
import { getThemesUrl, themesAxios } from "./themesService";

const useThemesQuery = (
  params?: PreparedConfig & ThemesQueryParams,
  swrConfig?: SWRConfiguration,
) =>
  useSWRAxios<ThemesRes>(
    {
      url: getThemesUrl,
      params,
    },
    themesAxios,
    swrConfig,
  );

export default useThemesQuery;
