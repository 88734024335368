import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useController, UseControllerProps } from "react-hook-form";

type MuiTextFieldProps<T> = UseControllerProps<T> & TextFieldProps;

const MuiTextField = <T,>({
  control,
  name,
  helperText,
  defaultValue,
  shouldUnregister,
  onChange,
  onBlur,
  ...rest
}: MuiTextFieldProps<T>): JSX.Element => {
  const {
    field: { ref, onChange: fieldOnChange, onBlur: fieldOnBlur, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    shouldUnregister,
  });

  return (
    <TextField
      {...rest}
      {...inputProps}
      onChange={(e) => {
        const v = e.target.value;
        fieldOnChange(v);
        if (onChange) onChange(e);
      }}
      onBlur={(e) => {
        fieldOnBlur();
        if (onBlur) onBlur(e);
      }}
      variant="filled"
      inputRef={ref}
      error={Boolean(error?.message)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default MuiTextField;
