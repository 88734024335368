import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useRedux";
import { roleAxios } from "../../hooks/queries/roles/roleService";
import { systemAxios } from "../../hooks/queries/system/systemService";
import { accessAxios } from "../../hooks/queries/access/accessService";
import { nfcAxios } from "../../modules/NFC/queries/nfcService";
import { userAxios } from "../../modules/User/queries/userService";

const AxiosProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { jwt } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!jwt) return;

    const axioses = [userAxios, nfcAxios, roleAxios, systemAxios, accessAxios];
    const interceptorIds: number[] = [];

    axioses.forEach((a) => {
      const authInterceptorId = a.interceptors.request.use((config) => {
        config.headers["Authorization"] = `Bearer ${jwt}`;
        return config;
      });
      // a.interceptors.response.use((res) => {
      //   console.log(res.config.params);
      //   return res;
      // });
      interceptorIds.push(authInterceptorId);
    });

    return () => {
      axioses.forEach((a, key) => {
        a.interceptors.request.eject(interceptorIds[key]);
      });
    };
  }, [jwt]);
  return <>{children}</>;
};

export default AxiosProvider;
