import StockholmIcon, { StockholmIconProps } from "../StockholmIcon";

const DuplicateIcon = (props: StockholmIconProps): JSX.Element => {
  return (
    <StockholmIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect opacity="0.5" x="7" y="2" width="14" height="16" rx="3" fill="black" />
        <rect x="3" y="6" width="14" height="16" rx="3" fill="black" />
      </svg>
    </StockholmIcon>
  );
};

export default DuplicateIcon;
