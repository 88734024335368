import { Tooltip, TooltipProps } from "@mui/material";
import clsx from "clsx";

type DrawerHeaderButtonProps = {
  /** button label */
  label?: string;
  /** button icon */
  icon?: JSX.Element;
  /** button on click handler */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** no margin end */
  noMe?: boolean;
  /** loading state */
  loading?: boolean;
  /** tooltip props */
  tooltipProps?: Omit<TooltipProps, "children">;
} & React.ComponentPropsWithoutRef<"button">;

const DrawerHeaderButton = ({
  label,
  icon,
  onClick,
  noMe,
  loading,
  className,
  tooltipProps,
  disabled,
  ...rest
}: DrawerHeaderButtonProps): JSX.Element => {
  const children = (
    <button
      {...rest}
      type="button"
      className={clsx("btn btn-sm", !label && "btn-icon", !noMe && "me-2", className)}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? <span className="spinner-border spinner-border-sm align-middle" /> : icon} {label}
    </button>
  );

  return tooltipProps ? <Tooltip {...tooltipProps}>{children}</Tooltip> : children;
};

export default DrawerHeaderButton;
