import clsx from "clsx";

const DrawerCard = ({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div className={clsx("card w-100", className)} {...props}>
      {children}
    </div>
  );
};

export default DrawerCard;
