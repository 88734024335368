import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useRedux";
import USFlagIcon from "../../Icons/Flags/USFlagIcon";
import Fade from "../../Transitions/Fade/Fade";

const UserMenu = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);
  const [open, setOpen] = useState(false);

  return (
    <div className="d-flex align-items-center ms-1 ms-lg-3 position-relative">
      {/* begin::Menu wrapper */}
      <div
        className="cursor-pointer symbol symbol-30px symbol-md-40px"
        onClick={() => setOpen((s) => !s)}
      >
        <img src={process.env.PUBLIC_URL + "/dummy/pp.jpg"} alt="theme" />
      </div>
      <div
        style={{
          position: "absolute",
          top: "100%",
          right: 0,
        }}
      >
        <Fade in={open} slide="up">
          {/* begin::Menu */}
          <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px show">
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                {/* begin::Avatar */}
                <div className="symbol symbol-50px me-5">
                  <img src={process.env.PUBLIC_URL + "/dummy/pp.jpg"} />
                </div>
                {/* end::Avatar */}
                {/* begin::Username */}
                <div className="d-flex flex-column">
                  <div className="fw-bolder d-flex align-items-center fs-5">{user?.name}</div>
                  <div className="fw-bold text-muted text-hover-primary fs-7">{user?.email}</div>
                </div>
                {/* end::Username */}
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className="separator my-2"></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className="menu-item px-5">
              <div className="menu-link px-5">
                <span className="menu-title position-relative">
                  Language
                  <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                    English
                    <USFlagIcon className="rounded-1 ms-2" />
                  </span>
                </span>
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className="menu-item px-5">
              <Link to="/logout" className="menu-link px-5">
                Log Out
              </Link>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </Fade>
      </div>
      {/* end::Menu wrapper */}
    </div>
  );
};

export default UserMenu;
