import { Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import useHeader from "../../hooks/useHeader";
import type { BreadcrumbItemType } from "../Layouts/Main/Breadcrumbs";

interface TitleRouteProps extends RouteProps {
  title: string;
  breadcrumbs?: BreadcrumbItemType[];
}

/**
 * Short hand for `useHeader`.
 * Use this Route Component if there is no any async title / breadcrumbs
 * Using `useHeader` again will replace the title and breadcrumbs.
 */
const TitledRoute = ({ title, breadcrumbs, children, ...props }: TitleRouteProps): JSX.Element => {
  useHeader(title, breadcrumbs);
  return <Route {...props}>{children}</Route>;
};

export default TitledRoute;
