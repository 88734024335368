import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { useController, UseControllerProps } from "react-hook-form";

type MuiSelectFieldProps<T> = UseControllerProps<T> &
  SelectProps & {
    /** Select Options */
    options: MenuItemProps[];
    /** Form Helper Text */
    helperText?: React.ReactNode;
    /** Text Field required */
    required?: boolean;
  };

const MuiSelectField = <T,>({
  control,
  name,
  options,
  helperText: propsHelperText,
  label,
  fullWidth,
  margin,
  placeholder,
  required,
  defaultValue,
  shouldUnregister,
  onChange,
  ...rest
}: MuiSelectFieldProps<T>): JSX.Element => {
  const {
    field: { ref, onChange: fieldOnChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    shouldUnregister,
  });
  const helperText = error?.message ?? propsHelperText;

  return (
    <FormControl
      error={Boolean(error?.message)}
      fullWidth={fullWidth}
      margin={margin}
      variant="filled"
    >
      <InputLabel id={`${name}Select`} required={required}>
        {label}
      </InputLabel>
      <Select
        {...rest}
        {...inputProps}
        onChange={(e, ...rest) => {
          const v = e.target.value;
          fieldOnChange(v);
          if (onChange) onChange(e, ...rest);
        }}
        inputRef={ref}
        labelId={`${name}Select`}
        displayEmpty
        renderValue={(v: MenuItemProps["value"]) =>
          Array.isArray(v) ? (
            v.length < 1 ? (
              <span className="opacity-50">{placeholder ?? "Choose Multiple"}</span>
            ) : (
              options
                .filter((x) => v.includes(x.value))
                .map((x) => x.children)
                .join(", ")
            )
          ) : v ? (
            options.find((x) => x.value === v)?.children
          ) : (
            <span className="opacity-50">{placeholder ?? "Choose"}</span>
          )
        }
        required={required}
      >
        {options.map((opt, i) => (
          <MenuItem value={opt?.value} key={i}>
            {opt.children}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MuiSelectField;
