import { InputAdornment, Tooltip } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import SearchIcon from "../../../components/Icons/Stockholm/General/SearchIcon";
import PlusIcon from "../../../components/Icons/Stockholm/Navigation/PlusIcon";
import { Tbody, Tr, Td } from "../../../components/Table";
import useUsersQuery, { UsersRes } from "../queries/useUsersQuery";
import usePages from "../../../hooks/usePages";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import UserCreate from "./UserCreate";
import MuiTextField from "../../../components/UncontrolledForm/MuiTextField";
import { userAxios } from "../queries/userService";
import dayjs from "dayjs";
import { useMemo } from "react";
import UserDetail from "./UserDetail/UserDetail";
import DisableIcon from "../../../components/Icons/Stockholm/General/DisableIcon";
import CopyTooltip from "../../../components/Tooltip/CopyTooltip";

const UserList = (): JSX.Element => {
  const { path, url } = useRouteMatch();
  const { config, setConfig, setParams, data, getRequest, isValidating, mutate } = useUsersQuery({
    defaultConfig: {
      sort: { key: "created_at", isAsc: false },
    },
  });

  const pages = usePages(data?.totalData ?? 0, config.page, config.limit, {
    includeFirstAndLast: true,
  });

  return (
    <div className="card">
      <Helmet title="User List" />
      <UserCreate path="/create" onClose={() => mutate()} />
      <UserDetail path="/detail/:id" onClose={mutate} url={url} />
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="d-flex align-items-center">
            <div>
              <MuiTextField
                margin="none"
                hiddenLabel
                placeholder="Search"
                onChange={(e) => {
                  const value = e.target.value;
                  setParams("search", value ? `username|${value}` : undefined);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon size="2" />
                    </InputAdornment>
                  ),
                }}
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
            <Link to={path + "/create"} className="btn btn-primary">
              <PlusIcon size="2" />
              Create User
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <DataTable
          columns={[
            { label: "No." },
            { label: "Username", key: "username" },
            { label: "Email", key: "email" },
            { label: "Name", key: "name" },
            { label: "Registered at", key: "created_at" },
          ]}
          loading={isValidating}
          total={data?.totalData ?? 0}
          config={config}
          setConfig={setConfig}
        >
          {pages.map((page) => (
            <Body key={page} request={getRequest(page)} active={page === config.page} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

type BodyProps = {
  /** axios config request */
  request: Omit<AxiosRequestConfig, "baseURL" | "method" | "data">;
  /** is showing */
  active: boolean;
} & React.ComponentPropsWithoutRef<"tbody">;

const Body = ({ request, active, className }: BodyProps): JSX.Element => {
  const { url } = useRouteMatch();
  const { data } = useSWRAxios<UsersRes>(request, userAxios);
  const startNo = useMemo(() => {
    const page = request.params.page ?? 0;
    const limit = request.params.limit ?? 0;
    return (page - 1) * limit + 1;
  }, [request]);

  return (
    <Tbody className={clsx("text-gray-600 fw-bold", !active && "d-none", className)}>
      {data?.data.map((d, i) => (
        <Tr key={d.id}>
          <Td>{startNo + i}</Td>
          <Td>
            <CopyTooltip url={`https://biobio.id/${d.username}`}>
              <Link
                to={`${url}/detail/${d.id}`}
                className="text-gray-800 text-hover-primary fw-bolder"
              >
                {d.username}
              </Link>
              {!!d.isBlocked && (
                <Tooltip title="Blocked" placement="top">
                  <div className="d-inline-block">
                    <DisableIcon size="2" color="danger" />
                  </div>
                </Tooltip>
              )}
            </CopyTooltip>
          </Td>
          <Td>
            <a
              href={`https://mail.google.com/mail/?tf=cm&fs=1&to=${d.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 text-hover-primary"
            >
              {d.email}
            </a>
          </Td>
          <Td>{d.name}</Td>
          <Td>{dayjs(d.created_at).format("DD MMM YYYY HH:mm:ss")}</Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default UserList;
