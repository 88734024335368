import axios from "axios";

export const nfcAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export const nfcUrls = {
  list: "/nfc",
  createBulk: "/nfc/bulk-by-product",
  detail: (id: number | string): string => `/nfc/${id}`,
  update: (id: number | string): string => `/nfc/${id}`,
  exportQRCodes: "/nfc/export-qr-code",
  connectedToUser: (userId: number | string): string => `/nfc/${userId}/connected-to-user`,
  bulkUpdateProduct: (productId: number | string): string =>
    `/nfc/bulk-update/${productId}/product`,
  unpair: (nfcId: number | string): string => `/nfc/${nfcId}/unpair`,
};

export const nfcBulkUpdateTheme = (themeId: string, body: { nfcs: number[] }) =>
  nfcAxios.post(`/nfc/bulk-update/${themeId}/theme`, body);
