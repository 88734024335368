import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

type MuiSelectFieldProps<T> = SelectProps<T> & {
  /** Select Options */
  options: MenuItemProps[];
  /** hidden label state */
  hiddenLabel?: boolean;
};

const MuiSelectField = <T extends undefined | string | number | string[]>({
  options,
  label,
  fullWidth,
  margin,
  placeholder,
  required,
  hiddenLabel,
  ...rest
}: MuiSelectFieldProps<T>): JSX.Element => {
  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant="filled" hiddenLabel={hiddenLabel}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Select
        {...rest}
        displayEmpty
        renderValue={(v: MenuItemProps["value"]) =>
          Array.isArray(v) ? (
            v.length < 1 ? (
              <span className="opacity-50">{placeholder ?? "Choose Multiple"}</span>
            ) : (
              options
                .filter((x) => v.includes(x.value))
                .map((x) => x.children)
                .join(", ")
            )
          ) : v ? (
            options.find((x) => x.value === v)?.children
          ) : (
            <span className="opacity-50">{placeholder ?? "Choose"}</span>
          )
        }
        required={required}
      >
        {options.map((opt, i) => (
          <MenuItem value={opt?.value} key={i}>
            {opt.children}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MuiSelectField;
