import { SWRConfiguration } from "swr";
import useDataTable, { DataTableOptions } from "../../../hooks/useDataTable";
import { productAxios, productService } from "./productService";
import { ProductType } from "./useProductQuery";

export type ProductsRes = {
  data: ProductType[];
  totalData?: number;
};

type ProductsQueryParams = {
  search?: string;
};

const useProductsQuery = (
  dataTableConfig?: DataTableOptions<ProductsQueryParams>,
  swrConfig?: SWRConfiguration,
) =>
  useDataTable<ProductsRes, ProductsQueryParams>(
    {
      url: productService.list,
    },
    productAxios,
    dataTableConfig,
    swrConfig,
  );

export default useProductsQuery;
