import { useMedia } from "react-use";
import { useAppSelector } from "../../../hooks/useRedux";
import Breadcrumbs from "./Breadcrumbs";

type TitleProps = {
  /**
   * determine in which screen size to show, mobile or desktop.
   * @default false
   */
  forMobile?: boolean;
};

const Title = ({ forMobile }: TitleProps): JSX.Element | null => {
  const matchMobileSize = useMedia("(max-width: 991px)");
  const show = (!forMobile && !matchMobileSize) || (forMobile && matchMobileSize);
  const { headerTitle } = useAppSelector((state) => state.config);

  return show ? (
    <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
      {/* begin::Title */}
      <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">{headerTitle}</h1>
      {/* end::Title */}
      {/* begin::Separator */}
      <span className="h-20px border-gray-200 border-start mx-4"></span>
      {/* end::Separator */}
      {/* begin::Breadcrumb */}
      <Breadcrumbs />
      {/* end::Breadcrumb */}
    </div>
  ) : null;
};

export default Title;
