import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";
import ProductsAutoComplete from "../../../Products/utilities/ProductsAutoComplete";
import { nfcAxios, nfcUrls } from "../../queries/nfcService";
import { NFCType } from "../../queries/useNFCQuery";

type UpdateBulkNFCProductProps = {
  /** selected nfc(s) */
  items: NFCType[];
  /** on update success callback */
  onSuccess: () => void;
};

const UpdateBulkNFCProduct = ({ items, onSuccess }: UpdateBulkNFCProductProps): JSX.Element => {
  const [selectedProductId, setSelectedProductId] = useState("");

  const [state, submit] = useAxiosAsync(
    async (productId: number | string, items: NFCType[]) => {
      try {
        const data = {
          nfcs: items.map((x) => x.id),
        };
        const response = await nfcAxios.post(nfcUrls.bulkUpdateProduct(productId), data);
        toast.success(`${items.length} NFC(s) Updated`);
        onSuccess();
        return response;
      } catch (error) {
        toast.error(`Failed to Update ${items.length} NFC(s)`);
        throw error;
      }
    },
    [onSuccess],
  );

  return (
    <div className="d-flex">
      <div className="w-200px me-3">
        <ProductsAutoComplete
          placeholder="Choose Product"
          onChange={(_e, v) => {
            if (v) setSelectedProductId(v.id.toString());
          }}
          textFieldProps={{
            hiddenLabel: true,
            margin: "none",
          }}
          disableClearable
        />
      </div>
      <div>
        <Button
          variant="base-primary"
          disabled={!selectedProductId}
          loading={state.loading}
          onClick={() => submit(selectedProductId, items)}
        >
          Update NFC
        </Button>
      </div>
    </div>
  );
};

export default UpdateBulkNFCProduct;
