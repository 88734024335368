import { Helmet } from "react-helmet-async";
import { Switch, useRouteMatch } from "react-router-dom";
import TitledRoute from "../../../components/Route/TitledRoute";
import NFCList from "./NFCList/NFCList";

const NFCRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet defaultTitle="NFCs | Biobio" titleTemplate="%s - NFCs | Biobio" />
      <Switch>
        <TitledRoute title="NFC List" path={path}>
          <NFCList />
        </TitledRoute>
      </Switch>
    </>
  );
};

export default NFCRoutes;
