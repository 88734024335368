import React from "react";
import { useCallback } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import Modal from "./Modal";

type PropsAreEqual<T> = (prevProps: Readonly<T>, nextProps: Readonly<T>) => boolean;

export interface WithRouteModalProps {
  path: string;
  onClose?: () => void;
}

const withRouteModal = <T extends WithRouteModalProps>(
  Component: {
    (props: T): JSX.Element;
    displayName?: string;
  },
  propsAreEqual?: PropsAreEqual<T> | false,

  componentName = Component.displayName ?? Component.name,
): {
  (props: T): JSX.Element;
  displayName: string;
} => {
  function WithRouteModal({ onClose, path, ...props }: T) {
    const { push } = useHistory();
    const { path: pathPrefix } = useRouteMatch();

    const handleClose = useCallback(() => {
      push(pathPrefix);
      onClose?.();
    }, [push, onClose]);

    return (
      <Route path={pathPrefix + path}>
        {({ match }) => (
          <Modal in={Boolean(match)} onClose={handleClose}>
            <Component {...(props as T)} path={path} onClose={handleClose} />
          </Modal>
        )}
      </Route>
    );
  }

  WithRouteModal.displayName = `withRouteModal(${componentName})`;

  const wrappedComponent =
    propsAreEqual === false ? WithRouteModal : React.memo(WithRouteModal, propsAreEqual);

  return wrappedComponent as typeof WithRouteModal;
};

export default withRouteModal;
