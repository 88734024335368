import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";

export type MuiAutocompleteProps<
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
  "renderInput" | "openOnFocus"
> & {
  /** Text Field props */
  textFieldProps?: TextFieldProps;
};

const MuiAutocomplete = <
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>({
  placeholder,
  textFieldProps,
  ...rest
}: MuiAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  return (
    <Autocomplete
      {...rest}
      openOnFocus
      renderInput={({ InputProps, ...params }) => {
        return (
          <TextField
            {...textFieldProps}
            {...params}
            InputProps={{
              ...InputProps,
              ...textFieldProps?.InputProps,
            }}
            label={textFieldProps?.label}
            placeholder={placeholder}
            variant="filled"
          />
        );
      }}
    />
  );
};

export default MuiAutocomplete;
