import { useEffect } from "react";
import type { BreadcrumbItemType } from "../components/Layouts/Main/Breadcrumbs";
import { configActions } from "../redux/slice/configSlice";
import { useAppDispatch } from "./useRedux";

const useHeader = (title: string, breadcrumbs?: BreadcrumbItemType[]): void => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(configActions.setHeader({ title, breadcrumbs }));
    return () => {
      dispatch(configActions.clearHeader());
    };
  }, [title, breadcrumbs]);
};

export default useHeader;
