import { toast } from "react-toastify";
import { DrawerHeaderButton } from "../../../../components/Drawer/DrawerCard";
import UnlinkIcon from "../../../../components/Icons/Stockholm/General/UnlinkIcon";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";
import { nfcAxios, nfcUrls } from "../../queries/nfcService";
import { NFCType } from "../../queries/useNFCQuery";

type NFCDetailUnpairProps = {
  nfc: NFCType;
  onSuccess: () => void;
};

const NFCDetailUnpair = ({ nfc, onSuccess }: NFCDetailUnpairProps): JSX.Element => {
  const [state, submit] = useAxiosAsync(async () => {
    try {
      const res = await nfcAxios.post(nfcUrls.unpair(nfc.id));
      toast.success("NFC Unpaired");
      onSuccess();
      return res;
    } catch (error) {
      toast.error("Failed to Unpair NFC");
      throw error;
    }
  }, []);

  return (
    <DrawerHeaderButton
      icon={<UnlinkIcon size="2" />}
      tooltipProps={{
        title: "Unpair",
      }}
      className="btn-active-light-danger"
      onClick={submit}
      loading={state.loading}
    />
  );
};

export default NFCDetailUnpair;
