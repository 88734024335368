import {
  DrawerBody,
  DrawerCard,
  DrawerHeader,
  DrawerHeaderButton,
  DrawerInfo,
} from "../../../../components/Drawer/DrawerCard";
import withRouteDrawer, {
  WithRouteDrawerProps,
} from "../../../../components/Drawer/withRouteDrawer";
import { useRouteMatch } from "react-router";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import EditIcon from "../../../../components/Icons/Stockholm/Interface/EditIcon";
import useNFCQuery from "../../queries/useNFCQuery";
import NFCDetailUnpair from "./NFCDetailUnpair";
import CopyTooltip from "../../../../components/Tooltip/CopyTooltip";
import NA from "../../../../components/NoAnswer/NA";

type ProductDetailProps = {
  url: string;
} & WithRouteDrawerProps;

const ProductDetail = ({ onClose, url }: ProductDetailProps): JSX.Element => {
  const match = useRouteMatch<{ id: string }>();
  const id = match?.params.id;
  const { data, isValidating, mutate } = useNFCQuery(id);

  return (
    <DrawerCard>
      <DrawerHeader
        title="Detail NFC"
        onClose={onClose}
        loading={isValidating}
        tools={
          data && (
            <>
              <Link to={`${url}/update/${id}`}>
                <DrawerHeaderButton
                  icon={<EditIcon size="5" />}
                  label="Edit"
                  className="btn-light-primary"
                />
              </Link>
              {data.user_id && <NFCDetailUnpair nfc={data} onSuccess={mutate} />}
            </>
          )
        }
      />
      <DrawerBody>
        {data ? (
          <>
            <DrawerInfo label="Code">
              <CopyTooltip url={data.url}>{data.code ?? <NA />}</CopyTooltip>
            </DrawerInfo>
            <DrawerInfo
              label="Product"
              to={data.product_id ? `/products/detail/${data.product_id}` : undefined}
            >
              {data.product?.name ?? data?.product_id ?? <NA />}
            </DrawerInfo>
            <DrawerInfo
              label="Theme"
              to={data.theme_id ? `/products/detail/${data.theme_id}` : undefined}
            >
              {data.theme?.name ?? data?.theme_id ?? <NA />}
            </DrawerInfo>
            <DrawerInfo label="Note">{data.note ? data.note : <NA />}</DrawerInfo>
            <DrawerInfo label="Linked to" to={`/user-management/users/detail/${data.user_id}`}>
              {data.user?.username ?? <NA />}
            </DrawerInfo>
            <DrawerInfo label="Last Linked at">
              {data.lastLinkedAt ? dayjs(data.lastLinkedAt).format("DD MMM YYYY HH:mm:ss") : ""}
            </DrawerInfo>
            <DrawerInfo label="Created at">
              {data.created_at ? dayjs(data.created_at).format("DD MMM YYYY HH:mm:ss") : ""}
            </DrawerInfo>
          </>
        ) : null}
      </DrawerBody>
    </DrawerCard>
  );
};

export default withRouteDrawer(ProductDetail);
