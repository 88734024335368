import ReactDOM from "react-dom";
import clsx from "clsx";
import Backdrop from "../Backdrop/Backdrop";
import Slide from "../Transitions/Slide/Slide";
import { useKey } from "react-use";

interface DrawerProps extends React.ComponentPropsWithoutRef<"div"> {
  in: boolean;
  position: "start" | "end";
  onClose?: () => void;
}
const bodyEl = document.body;

const Drawer = ({
  in: inProp,
  position,
  onClose,
  children,
  className,
  ...props
}: DrawerProps): JSX.Element => {
  const drawerEscClose = () => {
    if (!onClose) return;
    const modalEl = document.getElementsByClassName("modal");
    if (!modalEl.length) onClose();
  };

  useKey("Escape", () => inProp && drawerEscClose(), undefined, [inProp, onClose]);

  return ReactDOM.createPortal(
    <>
      <Slide
        in={inProp}
        slide={position === "start" ? "right" : "left"}
        className={clsx("drawer", className, position === "start" ? "drawer-start" : "drawer-end")}
        {...props}
      >
        {children}
      </Slide>
      <Backdrop in={inProp} onClick={onClose} />
    </>,
    bodyEl,
  );
};

export default Drawer;
