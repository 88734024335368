import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import { useController, UseControllerProps } from "react-hook-form";

type MuiCheckboxProps<T> = UseControllerProps<T> &
  CheckboxProps & {
    /** Form Control Label */
    label: FormControlLabelProps["label"];
    /** Form Helper Text */
    helperText?: React.ReactNode;
    /** formControlProps */
    formControlProps?: Omit<FormControlProps<"fieldset">, "component" | "error">;
  };

const MuiCheckbox = <T,>({
  control,
  name,
  helperText: propsHelperText,
  label,
  formControlProps,
  defaultValue,
  shouldUnregister,
  ...rest
}: MuiCheckboxProps<T>): JSX.Element => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    shouldUnregister,
  });
  const helperText = error?.message ?? propsHelperText;
  const checked = typeof value === "boolean" ? value : false;

  return (
    <FormControl {...formControlProps} error={Boolean(error?.message)} component="fieldset">
      <FormControlLabel
        label={label}
        control={<Checkbox {...rest} {...inputProps} checked={checked} inputRef={ref} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MuiCheckbox;
