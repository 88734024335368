import { Redirect, Route, Switch } from "react-router-dom";
import { useAppSelector } from "./hooks/useRedux";
import { SplashScreen } from "./components/SplashScreen/SplashScreen";
import AuthRoute from "./components/Route/AuthRoute";
import NoAuthRoute from "./components/Route/NoAuthRoute";
import LogoutPage from "./pages/Logout/LogoutPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import LoginPage from "./pages/Login/LoginPage";
import MainLayout from "./components/Layouts/Main/MainLayout";
import NFCRoutes from "./modules/NFC/components/NFCRoutes";
import RolePage from "./pages/Role/RolePage";
import TitledRoute from "./components/Route/TitledRoute";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";
import UserPage from "./modules/User/components/UserPage";
import ProductsRoutes from "./modules/Products/components/ProductsRoutes";
import ThemesRoutes from "./modules/Themes/components/ThemesRoutes";

const AppRoutes = (): JSX.Element => {
  const { jwt, user } = useAppSelector((state) => state.auth);

  return jwt && !user ? (
    <SplashScreen title="Loading User Data" />
  ) : (
    // No need to render switch route while fetching auth user
    <Switch>
      {/*
       * Please follow Route order below
       * Route[]
       * NoAuthRoute[]
       * AuthRoute[]
       */}
      <Route exact path="/auth/reset-password">
        <ResetPasswordPage />
      </Route>
      <NoAuthRoute path="/login">
        <LoginPage />
      </NoAuthRoute>
      <AuthRoute>
        <MainRoutes />
      </AuthRoute>
      <Redirect to="/404" />
    </Switch>
  );
};

const MainRoutes = (): JSX.Element => {
  return (
    <MainLayout>
      <Switch>
        <TitledRoute title="Dashboard" exact path="/">
          <DashboardPage />
        </TitledRoute>
        <Route path="/nfc">
          <NFCRoutes />
        </Route>
        <Route path="/products">
          <ProductsRoutes />
        </Route>
        <Route path="/themes">
          <ThemesRoutes />
        </Route>
        <Route path="/users">
          <UserPage />
        </Route>
        <Route path="/roles">
          <RolePage />
        </Route>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Redirect to="/404" />
      </Switch>
    </MainLayout>
  );
};

export default AppRoutes;
