import { Slide, ToastContainer } from "react-toastify";
import Button from "../Button/Button";
import CloseIcon from "../Icons/Stockholm/Navigation/CloseIcon";

const typeToVariantMap = {
  info: "primary",
  error: "danger",
  warning: "warning",
  success: "success",
};

type ToastCloseButtonProps = {
  closeToast: () => void;
  type: keyof typeof typeToVariantMap;
};

const ToastCloseButton = ({ closeToast, type }: ToastCloseButtonProps) => {
  return (
    <Button
      type="button"
      className={`btn btn-sm btn-active-light-${typeToVariantMap[type]} btn-icon`}
      onClick={closeToast}
    >
      <CloseIcon size="2" />
    </Button>
  );
};

const ToastifyProvider = ({ children }: React.ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <>
      <ToastContainer
        autoClose={5000}
        transition={Slide}
        position="bottom-right"
        closeButton={ToastCloseButton}
      />
      {children}
    </>
  );
};

export default ToastifyProvider;
