import styles from "./progress.module.scss";

type ProgressProps = {
  /** determine show or not */
  in?: boolean;
};

const Progress = ({ in: inProp }: ProgressProps): JSX.Element | null => {
  return inProp ? <div className={styles.root} /> : null;
};

export default Progress;
