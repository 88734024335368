type LogoProps = {
  size: number;
  white?: boolean;
};

const Logo = ({ size = 1695, white }: LogoProps): JSX.Element => {
  return (
    <svg width={size} viewBox="0 0 1695 450" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M847.5 0C826.789 0 810 16.7893 810 37.5V300C810 382.843 877.157 450 960 450C1042.84 450 1110 382.843 1110 300C1110 217.157 1042.84 150 960 150C932.678 150 907.063 157.305 885 170.067V37.5C885 16.7893 868.211 0 847.5 0ZM885 300C885 341.421 918.579 375 960 375C1001.42 375 1035 341.421 1035 300C1035 258.579 1001.42 225 960 225C918.579 225 885 258.579 885 300Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M1695 412.5C1695 433.211 1678.21 450 1657.5 450C1636.79 450 1620 433.211 1620 412.5C1620 391.789 1636.79 375 1657.5 375C1678.21 375 1695 391.789 1695 412.5Z"
        fill={white ? "white" : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1275 300C1275 217.157 1342.16 150 1425 150C1507.84 150 1575 217.157 1575 300C1575 382.843 1507.84 450 1425 450C1342.16 450 1275 382.843 1275 300ZM1425 375C1383.58 375 1350 341.421 1350 300C1350 258.579 1383.58 225 1425 225C1466.42 225 1500 258.579 1500 300C1500 341.421 1466.42 375 1425 375Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M1155 187.5C1155 166.789 1171.79 150 1192.5 150C1213.21 150 1230 166.789 1230 187.5V412.5C1230 433.211 1213.21 450 1192.5 450C1171.79 450 1155 433.211 1155 412.5V187.5Z"
        fill={white ? "white" : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 300V37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5V170.067C97.063 157.305 122.678 150 150 150C232.843 150 300 217.157 300 300C300 382.843 232.843 450 150 450C67.1573 450 0 382.843 0 300ZM75 300C75 341.421 108.579 375 150 375C191.421 375 225 341.421 225 300C225 258.579 191.421 225 150 225C108.579 225 75 258.579 75 300Z"
        fill={white ? "white" : "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M465 300C465 217.157 532.157 150 615 150C697.843 150 765 217.157 765 300C765 382.843 697.843 450 615 450C532.157 450 465 382.843 465 300ZM615 375C573.579 375 540 341.421 540 300C540 258.579 573.579 225 615 225C656.421 225 690 258.579 690 300C690 341.421 656.421 375 615 375Z"
        fill={white ? "white" : "black"}
      />
      <path
        d="M345 187.5C345 166.789 361.789 150 382.5 150C403.211 150 420 166.789 420 187.5V412.5C420 433.211 403.211 450 382.5 450C361.789 450 345 433.211 345 412.5V187.5Z"
        fill={white ? "white" : "black"}
      />
    </svg>
  );
};

export default Logo;
