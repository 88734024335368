import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { authActions } from "../../redux/slice/authSlice";
import Alert from "../../components/Alert/Alert";
import Warning2Icon from "../../components/Icons/Stockholm/Code/Warning2Icon";
import MuiTextField from "../../components/ControlledForm/MuiTextField";
import useAxiosAsync from "../../hooks/useAxiosAsync";
import { InputAdornment } from "@mui/material";
import VisibleIcon from "../../components/Icons/Stockholm/General/VisibleIcon";
import VisibleOffIcon from "../../components/Icons/Stockholm/General/VisibleOffIcon";
import { useState } from "react";
import { userAxios, userUrls } from "../../modules/User/queries/userService";

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

type LoginFormValues = {
  email: string;
  password: string;
};

type LoginRes = {
  data: {
    token: string;
  };
};

const LoginForm = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [loginState, login]: any = useAxiosAsync(async (payload: LoginFormValues) => {
    const response = await userAxios.post<LoginRes>(userUrls.login, payload);
    dispatch(authActions.setToken(response.data.data));
    return response;
  }, []);

  const { control, handleSubmit } = useForm<LoginFormValues>({
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);

  return (
    <form onSubmit={handleSubmit(login)}>
      <MuiTextField
        className="mb-4 "
        control={control}
        name="email"
        label="Email"
        placeholder="Input Email"
        type="email"
        defaultValue=""
      />
      <MuiTextField
        className="mb-4 "
        control={control}
        name="password"
        label="Password"
        placeholder="Input Password"
        type={showPassword ? "text" : "password"}
        defaultValue=""
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                tabIndex={-1}
                type="button"
                className="btn-icon"
                onClick={() => setShowPassword((s) => !s)}
                icon={showPassword ? <VisibleOffIcon size="2" /> : <VisibleIcon size="2" />}
              />
            </InputAdornment>
          ),
        }}
      />
      {loginState.error && (
        <div className="mt-7">
          <Alert variant="danger" Icon={Warning2Icon}>
            {loginState?.error?.response?.data?.message ??
              loginState?.error.message ??
              "Something went wrong"}
          </Alert>
        </div>
      )}
      <div className="d-flex mt-7">
        <Button type="submit" variant="base-primary" loading={loginState.loading}>
          Login
        </Button>
        <Link to="/login/forgot-password" className="btn text-primary">
          Forgot Password?
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
