import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../useSWRAxios";
import { roleAxios, roleUrls } from "./roleService";

export type RoleType = {
  roleName: string;
  access: string[];
  idMasterRoles: number;
};

const useRoleQuery = (idMasterRoles?: number | string, swrConfig?: SWRConfiguration) =>
  useSWRAxios<RoleType>(
    idMasterRoles
      ? {
          url: roleUrls.detail(idMasterRoles),
          method: "GET",
        }
      : null,
    roleAxios,
    swrConfig,
  );

export default useRoleQuery;
