import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../../components/Button/Button";
import MuiTextField from "../../../components/ControlledForm/MuiTextField";
import {
  DrawerBody,
  DrawerFooter,
  DrawerForm,
  DrawerHeader,
} from "../../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../../components/Drawer/withRouteDrawer";
import { userAxios, userUrls } from "../queries/userService";
import { toast } from "react-toastify";
import { InputAdornment } from "@mui/material";
import VisibleOffIcon from "../../../components/Icons/Stockholm/General/VisibleOffIcon";
import VisibleIcon from "../../../components/Icons/Stockholm/General/VisibleIcon";
import useAxiosAsync from "../../../hooks/useAxiosAsync";
import { useState } from "react";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required().min(8),
});

type FormValues = {
  name: string;
  email: string;
  password: string;
};

const UserCreate = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const { handleSubmit, control } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const [state, create] = useAxiosAsync(
    async (values: FormValues) => {
      try {
        const response = await userAxios.post(userUrls.register, values);
        toast.success("User Created");
        onClose?.();
        return response;
      } catch (error) {
        toast.error("Failed to Create user");
        throw error;
      }
    },
    [handleSubmit, onClose],
  );

  const [showPassword, setShowPassword] = useState(false);

  return (
    <DrawerForm onSubmit={handleSubmit(create)}>
      <DrawerHeader title="Create User" onClose={onClose} />
      <DrawerBody>
        <MuiTextField
          control={control}
          label="Name"
          name="name"
          placeholder="e.g Alyssa Emily"
          defaultValue=""
        />
        <MuiTextField
          control={control}
          label="Email"
          name="email"
          placeholder="e.g alyssa.emily@gmail.com"
          defaultValue=""
        />
        <MuiTextField
          className="mb-4 "
          control={control}
          name="password"
          label="Password"
          placeholder="e.g emily.alyssa"
          type={showPassword ? "text" : "password"}
          defaultValue=""
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  tabIndex={-1}
                  type="button"
                  className="btn-icon"
                  onClick={() => setShowPassword((s) => !s)}
                  icon={showPassword ? <VisibleOffIcon size="2" /> : <VisibleIcon size="2" />}
                />
              </InputAdornment>
            ),
          }}
        />
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerForm>
  );
};

export default withRouteDrawer(UserCreate);
