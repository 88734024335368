import Button from "../../../components/Button/Button";
import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../../components/Drawer/withRouteDrawer";
import { toast } from "react-toastify";
import useAxiosAsync from "../../../hooks/useAxiosAsync";
import ThemesForm, { ThemesFormValues } from "./ThemesForm";
import { createTheme } from "../queries/themesService";

const formId = "themeCreate";

const ThemesCreate = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const [state, submit] = useAxiosAsync(
    async (values: ThemesFormValues) => {
      try {
        const response = await createTheme(values);
        toast.success("Theme Created");
        onClose();
        return response;
      } catch (error) {
        toast.error("Failed to Create Theme");
        throw error;
      }
    },
    [onClose],
  );

  return (
    <DrawerCard>
      <DrawerHeader title="Create Theme" onClose={onClose} />
      <DrawerBody>
        <ThemesForm formId={formId} onSubmit={submit} />
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

export default withRouteDrawer(ThemesCreate);
