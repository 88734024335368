import type {} from "@mui/x-date-pickers/themeAugmentation";
import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: `"Poppins",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  },
  palette: {
    primary: {
      main: "#009EF7",
      contrastText: "#fff",
    },
    error: {
      main: "#F1416C",
    },
    text: {
      primary: "#181c32",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: ".9rem",
          "&::first-letter": {
            textTransform: "uppercase",
          },
          "*::first-letter": {
            textTransform: "uppercase",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: "#181c32",
        },
        filled: {
          transform: "translate(12px, 7px) scale(1)",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        margin: "normal",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          fontSize: "1.1rem",
          borderRadius: "0.475rem",
          backgroundColor: "#f5f8fa",
          ":hover": {
            backgroundColor: "#f5f8fa",
          },
          "&.Mui-focused": {
            backgroundColor: "#eef3f7",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        },
        input: {
          paddingTop: 30,
          ":-webkit-autofill": {
            borderRadius: "inherit",
          },
        },
        multiline: {
          padding: "30px 12px 8px",
          "&.MuiInputBase-hiddenLabel": {
            padding: "10px 12px",
          },
        },
        inputHiddenLabel: {
          paddingTop: 10,
          paddingBottom: 10,
        },
        inputMultiline: {
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          ":focus": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        margin: "normal",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 10%)",
          borderRadius: ".475rem",
        },
        inputRoot: {
          paddingTop: 24,
          "&.MuiInputBase-hiddenLabel": {
            paddingTop: 3,
            paddingBottom: 3,
          },
          "&.MuiInputBase-adornedStart": {
            paddingLeft: 12,
            input: {
              paddingLeft: 0,
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.847rem",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 10%)",
          borderRadius: ".475rem",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: ".9rem",
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          button: {
            backgroundColor: "unset",
            border: "unset",
          },
        },
      },
    },
  },
});

export default theme;
