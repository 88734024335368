export default function objectToArray(obj: Record<string, string>) {
  try {
    return Object.keys(obj).map((x) => ({
      key: x,
      value: obj![x],
    }));
  } catch (error) {
    return [];
  }
}
