import { InputAdornment } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import SearchIcon from "../../components/Icons/Stockholm/General/SearchIcon";
import PlusIcon from "../../components/Icons/Stockholm/Navigation/PlusIcon";
import { Tbody, Tr, Td } from "../../components/Table";
import usePages from "../../hooks/usePages";
import { useSWRAxios } from "../../hooks/useSWRAxios";
import RoleCreate from "./RoleCreate";
import MuiTextField from "../../components/UncontrolledForm/MuiTextField";
import useRolesQuery, { RolesRes } from "../../hooks/queries/roles/useRolesQuery";
import { roleAxios } from "../../hooks/queries/roles/roleService";
import RoleDetail from "./RoleDetail";
import RoleUpdate from "./RoleUpdate";
import useAccess from "../../hooks/useAccess";
import NoAccess from "../../components/Access/NoAccess";

const RoleList = (): JSX.Element => {
  const haveAccess = useAccess(["role.view-list", "role.create", "role.edit"]);
  const { path } = useRouteMatch();
  const { config, setConfig, setParams, data, getRequest, isValidating, mutate } = useRolesQuery();

  const pages = usePages(data?.paging.totalData ?? 0, config.page, config.limit, {
    includeFirstAndLast: true,
  });

  return (
    <div className="card">
      <Helmet title="Role List" />
      {haveAccess[1] && <RoleCreate path="/create" onClose={() => mutate()} />}
      <RoleDetail path="/detail/:idMasterRoles" onClose={() => mutate()} />
      {haveAccess[2] && <RoleUpdate path="/update/:idMasterRoles" onClose={() => mutate()} />}
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {haveAccess[0] && (
            <div>
              <MuiTextField
                margin="none"
                hiddenLabel
                placeholder="Search"
                onChange={(e) => setParams("search", e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon size="2" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
            {haveAccess[1] && (
              <Link to={path + "/create"} className="btn btn-primary">
                <PlusIcon size="2" />
                Create Role
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        {haveAccess[0] ? (
          <DataTable
            columns={[
              { label: "ID", key: "idMasterRoles" },
              { label: "Name", key: "roleName" },
            ]}
            loading={isValidating}
            total={data?.paging.totalData}
            config={config}
            setConfig={setConfig}
          >
            {pages.map((page) => (
              <Body key={page} request={getRequest(page)} active={page === config.page} />
            ))}
          </DataTable>
        ) : (
          <NoAccess label="Role List" />
        )}
      </div>
    </div>
  );
};

type BodyProps = {
  /** axios config request */
  request: Omit<AxiosRequestConfig, "baseURL" | "method" | "data">;
  /** is showing */
  active: boolean;
} & React.ComponentPropsWithoutRef<"tbody">;

const Body = ({ request, active, className }: BodyProps): JSX.Element => {
  const { url } = useRouteMatch();
  const { data } = useSWRAxios<RolesRes>(request, roleAxios);

  return (
    <Tbody className={clsx("text-gray-600 fw-bold", !active && "d-none", className)}>
      {data?.data.map((d) => (
        <Tr key={d.idMasterRoles}>
          <Td>{d.idMasterRoles}</Td>
          <Td>
            <Link
              to={`${url}/detail/${d.idMasterRoles}`}
              className="text-gray-800 text-hover-primary fw-bolder"
            >
              {d.roleName}
            </Link>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default RoleList;
