import StockholmIcon, { StockholmIconProps } from "../StockholmIcon";

const ArrowToLeftIcon = (props: StockholmIconProps): JSX.Element => {
  return (
    <StockholmIcon {...props}>
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <rect
            fill="#000000"
            opacity="0.5"
            transform="translate(14.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-14.000000, -12.000000) "
            x="13"
            y="5"
            width="2"
            height="14"
            rx="1"
          />
          <rect fill="#000000" opacity="0.5" x="3" y="3" width="2" height="18" rx="1" />
          <path
            d="M5.7071045,15.7071045 C5.3165802,16.0976288 4.68341522,16.0976288 4.29289093,15.7071045 C3.90236664,15.3165802 3.90236664,14.6834152 4.29289093,14.2928909 L10.2928909,8.29289093 C10.6714699,7.914312 11.2810563,7.90106637 11.6757223,8.26284357 L17.6757223,13.7628436 C18.0828413,14.136036 18.1103443,14.7686034 17.7371519,15.1757223 C17.3639594,15.5828413 16.7313921,15.6103443 16.3242731,15.2371519 L11.0300735,10.3841355 L5.7071045,15.7071045 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(11.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-11.000001, -11.999997) "
          />
        </g>
      </svg>
    </StockholmIcon>
  );
};

export default ArrowToLeftIcon;
