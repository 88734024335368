import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import { nfcAxios, nfcUrls } from "./nfcService";
import { NFCType } from "./useNFCQuery";

type ConnectedNFCRes = {
  data: NFCType[];
};

const useConnectedNFCQuery = (userId?: number | string, swrConfig?: SWRConfiguration) =>
  useSWRAxios<ConnectedNFCRes>(
    userId
      ? {
          url: nfcUrls.connectedToUser(userId),
          method: "GET",
        }
      : null,
    nfcAxios,
    swrConfig,
  );

export default useConnectedNFCQuery;
