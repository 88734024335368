import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";

type MuiCheckboxProps = CheckboxProps & {
  /** Form Control Label */
  label: FormControlLabelProps["label"];
  /** Form Helper Text */
  helperText?: React.ReactNode;
  /** formControlProps */
  formControlProps?: Omit<FormControlProps<"fieldset">, "component" | "error">;
};

const MuiCheckbox = ({
  helperText,
  label,
  formControlProps,
  ...rest
}: MuiCheckboxProps): JSX.Element => {
  return (
    <FormControl {...formControlProps} component="fieldset">
      <FormControlLabel label={label} control={<Checkbox {...rest} />} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MuiCheckbox;
