import { useAsyncFn } from "react-use";
import Button from "../../components/Button/Button";
import {
  DrawerBody,
  DrawerCard,
  DrawerFooter,
  DrawerHeader,
} from "../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../components/Drawer/withRouteDrawer";
import { roleAxios, roleUrls, UpdateRoleResponse } from "../../hooks/queries/roles/roleService";
import { toast } from "react-toastify";
import RoleForm, { RoleFormSubmittedValues } from "./RoleForm";
import { useHistory, useRouteMatch } from "react-router";
import useRoleQuery from "../../hooks/queries/roles/useRoleQuery";

const formId = "updateRoleForm";

const RoleUpdate = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const { push } = useHistory();
  const match = useRouteMatch<{ idMasterRoles: string }>();
  const idMasterRoles = match?.params.idMasterRoles;
  const { data, isValidating, mutate } = useRoleQuery(idMasterRoles);

  const [state, submit] = useAsyncFn(
    async (values: RoleFormSubmittedValues) => {
      const response = await roleAxios.patch<UpdateRoleResponse>(
        roleUrls.update(idMasterRoles),
        values,
      );
      toast.success("Role Updated");
      mutate();
      push(`/user-management/roles/detail/${response.data.idMasterRoles}`);
      return response;
    },
    [mutate, push],
  );

  return (
    <DrawerCard>
      <DrawerHeader title="Update Role" onClose={onClose} loading={isValidating} />
      <DrawerBody>
        {data ? (
          <RoleForm
            formId={formId}
            onSubmit={submit}
            defaultValues={{ roleName: data.roleName, access: data.access }}
          />
        ) : null}
      </DrawerBody>
      <DrawerFooter>
        <Button type="button" onClick={onClose} variant="base-white" className="me-3">
          Discard
        </Button>
        <Button type="submit" variant="base-primary" form={formId} loading={state.loading}>
          Submit
        </Button>
      </DrawerFooter>
    </DrawerCard>
  );
};

export default withRouteDrawer(RoleUpdate);
