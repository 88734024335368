import clsx from "clsx";
import { Link, LinkProps } from "react-router-dom";

type DrawerInfoProps = {
  label?: React.ReactNode;
  labelProps?: React.ComponentPropsWithoutRef<"div">;
  to?: LinkProps["to"];
} & React.ComponentPropsWithoutRef<"div">;

const DrawerInfo = ({
  className,
  label,
  labelProps,
  to,
  children,
}: DrawerInfoProps): JSX.Element => {
  return (
    <div className={clsx("row mb-5", className)}>
      <div className="col-5">
        <div {...labelProps} className={clsx("text-muted", labelProps?.className)}>
          {label}
        </div>
      </div>
      <div className="col-7">
        <div className="fw-bold">{to ? <Link to={to}>{children}</Link> : children}</div>
      </div>
    </div>
  );
};

export default DrawerInfo;
