import { SWRConfiguration } from "swr";
import useDataTable, { DataTableOptions } from "../../../hooks/useDataTable";
import { nfcAxios, nfcUrls } from "./nfcService";
import { NFCType } from "./useNFCQuery";

export type NFCsRes = {
  data: NFCType[];
  totalData?: number;
};

type NFCsQueryParams = {
  search?: `url|${string}` | `code|${string}` | `user_id|${string}`;
  url?: string;
  userId?: string;
  productId?: string;
};

const useNFCsQuery = (
  dataTableConfig?: DataTableOptions<NFCsQueryParams>,
  swrConfig?: SWRConfiguration,
) =>
  useDataTable<NFCsRes, NFCsQueryParams>(
    {
      url: nfcUrls.list,
    },
    nfcAxios,
    dataTableConfig,
    swrConfig,
  );

export default useNFCsQuery;
