import { useSpring, animated } from "react-spring";
import { useMeasure } from "react-use";

type CollapseProps = {
  in: boolean;
  children: React.ReactElement;
};

const Collapse = ({ in: inProp, children }: CollapseProps): JSX.Element => {
  const [ref, { height: elHeight }] = useMeasure<HTMLDivElement>();
  const { height, opacity, y } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      height: inProp ? elHeight : 0,
      opacity: inProp ? 1 : 0,
      y: inProp ? 0 : 20,
    },
  });

  return (
    <animated.div style={{ overflow: "hidden", height, opacity }}>
      <animated.div ref={ref} style={{ y }}>
        {children}
      </animated.div>
    </animated.div>
  );
};

export default Collapse;
