import StockholmIcon, { StockholmIconProps } from "../StockholmIcon";

const DashboardIcon = (props: StockholmIconProps): JSX.Element => {
  return (
    <StockholmIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect x="2" y="2" width="9" height="9" rx="2" fill="black"></rect>
        <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black"></rect>
        <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black"></rect>
        <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black"></rect>
      </svg>
    </StockholmIcon>
  );
};

export default DashboardIcon;
