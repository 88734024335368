import axios from "axios";

export const productAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export const productService = {
  list: "/product",
  create: "/product",
  detail: (id: number | string): string => `/product/${id}`,
  update: (id: number | string): string => `/product/${id}`,
  getAllType: "/product/type",
};
