import { IconButton } from "@mui/material";
import { useState } from "react";
import { QrReader } from "react-qr-reader";
import QRCodeIcon from "../../../../components/Icons/Stockholm/Code/QRCodeIcon";
import Modal from "../../../../components/Modal/Modal";
import useMobileDetect from "../../../../hooks/useMobileDetect";

type ScanQRCodeButton = {
  onScan: (text: string) => void;
};

const ScanQRCodeButton = ({ onScan }: ScanQRCodeButton): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useMobileDetect();

  return (
    <>
      <IconButton onClick={() => setOpen((s) => !s)} size="small" edge="end">
        <QRCodeIcon size="2" />
      </IconButton>
      <Modal in={open} onClose={() => setOpen(false)}>
        <div className="py-8 text-center">
          <h4 className="mb-9 fw-boldest">Scan QR</h4>
          <div className="mw-400px m-auto">
            <QrReader
              constraints={{ facingMode: isMobile() ? "environment" : "user" }}
              onResult={(result, error) => {
                if (result) {
                  const text = result.getText();
                  if (text) {
                    setOpen(false);
                    onScan(text);
                  }
                }

                if (error) {
                  // No Camera / Access
                  // toast.error("Camera not detected / no access, please turn on Camera");
                  // console.info(error);
                }
              }}
              ViewFinder={ViewFinder}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const ViewFinder = () => {
  return (
    <svg
      width="50px"
      viewBox="0 0 100 100"
      style={{
        top: 0,
        left: 0,
        zIndex: 1,
        border: "50px solid rgba(0, 0, 0, 0.3)",
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      <path fill="none" d="M13,0 L0,0 L0,13" stroke="rgba(255, 0, 0, 0.5)" strokeWidth="5"></path>
      <path
        fill="none"
        d="M0,87 L0,100 L13,100"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      ></path>
      <path
        fill="none"
        d="M87,100 L100,100 L100,87"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      ></path>
      <path
        fill="none"
        d="M100,13 L100,0 87,0"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      ></path>
    </svg>
  );
};

export default ScanQRCodeButton;
