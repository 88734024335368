import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useMemo } from "react";
import { FilePond as FP, FilePondProps as FPProps } from "react-filepond";
import { FieldError, useController, UseControllerProps } from "react-hook-form";

type FilePondProps<T> = UseControllerProps<T> &
  FPProps & {
    helperText?: React.ReactNode;
    label?: React.ReactNode;
    required?: boolean;
    initialPictureSrc?: string;
  };

const FilePond = <T,>({
  control,
  name,
  helperText: propsHelperText,
  label,
  required,
  initialPictureSrc,
  ...rest
}: FilePondProps<T>): JSX.Element => {
  const {
    field: { onChange, name: inputName },
    fieldState: { error },
  } = useController({
    name,
    control,
    shouldUnregister: true,
  });

  const errorMessage = useMemo(() => {
    if (Array.isArray(error)) {
      const arrayOfError = error as FieldError[];
      return arrayOfError.map(
        (err, key) =>
          err?.message && (
            <span className="d-block" key={key}>
              {err.message}
            </span>
          ),
      );
    } else if (typeof error === "object") {
      return error?.message;
    }
    return undefined;
  }, [error]);

  const helperText = errorMessage ?? propsHelperText;

  return (
    <FormControl
      error={Boolean(error)}
      fullWidth
      margin="normal"
      variant="filled"
      className="mui-filled-input-bg"
    >
      <InputLabel required={required}>{label}</InputLabel>
      {initialPictureSrc && (
        <div className="pt-10 px-4">
          <div className="text-muted">Existing Picture</div>
          <div>
            <img className="img-thumbnail mh-100px" src={initialPictureSrc} alt="" />
          </div>
        </div>
      )}
      <FP
        {...rest}
        name={inputName}
        labelIdle={`Drag & Drop your files or <span class="filepond--label-action">Browse</span>`}
        labelTapToUndo="tap to clear"
        instantUpload={false}
        onupdatefiles={(files) => {
          onChange(files.map((file) => file.file));
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FilePond;
