import axios from "axios";
// import mock from "./userMock";

export const userAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export const userUrls = {
  me: "/me",
  login: "/login",
  forgotPassword: "/users/forgot-password",
  register: "/register",
  changePassword: "/users/change-password",
  list: "/user",
  detail: (id: number | string): string => `/user/${id}`,
  update: (id: number | string): string => `/user/${id}`,
  block: (id: number | string): string => `/user/${id}/block`,
  unblock: (id: number | string): string => `/user/${id}/unblock`,
};

// mock(userAxios);
