import { SWRConfiguration } from "swr";
import useDataTable, { DataTableOptions } from "../../../hooks/useDataTable";
import { userAxios, userUrls } from "./userService";
import { UserType } from "./useUserQuery";

export type UsersRes = {
  data: UserType[];
  totalData: number;
};

type UsersQueryParams = {
  search?: string;
  profileDivisionId?: string | number;
};

const useUsersQuery = (
  dataTableConfig?: DataTableOptions<UsersQueryParams>,
  swrConfig?: SWRConfiguration,
) =>
  useDataTable<UsersRes, UsersQueryParams>(
    {
      url: userUrls.list,
    },
    userAxios,
    dataTableConfig,
    swrConfig,
  );

export default useUsersQuery;
