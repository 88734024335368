import { Link, LinkProps } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useRedux";

export type BreadcrumbItemType = {
  label?: string;
  to?: LinkProps["to"];
};

const Breadcrumbs = (): JSX.Element => {
  const { headerBreadcrumbs } = useAppSelector((state) => state.config);

  return (
    <ul className="breadcrumb breadcrumb-angle fw-bold ms-4">
      {headerBreadcrumbs?.map((b, key) => (
        <BreadcrumbItem key={key} breadcrumb={b} first />
      ))}
    </ul>
  );
};

type BreadcrumbItemProps = {
  breadcrumb: BreadcrumbItemType;
  first?: boolean;
  urlPrefix?: string;
};

const BreadcrumbItem = ({ breadcrumb }: BreadcrumbItemProps) => {
  const label = breadcrumb.label ?? <span className="spinner-border spinner-border-sm" />;

  return (
    <li className="breadcrumb-item text-primary-light-grey">
      {breadcrumb.to ? <Link to={breadcrumb.to}>{label}</Link> : label}
    </li>
  );
};

export default Breadcrumbs;
