import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";

type MuiSwitchProps = SwitchProps & {
  /** Form Control Label */
  label: FormControlLabelProps["label"];
  /** Form Helper Text */
  helperText?: React.ReactNode;
  /** formControlProps */
  formControlProps?: Omit<FormControlProps<"fieldset">, "component" | "error">;
};

const MuiSwitch = ({
  helperText,
  label,
  formControlProps,
  ...rest
}: MuiSwitchProps): JSX.Element => {
  return (
    <FormControl {...formControlProps} component="fieldset">
      <FormControlLabel label={label} control={<Switch {...rest} />} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MuiSwitch;
