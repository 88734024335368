import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import { ProductType } from "../../Products/queries/useProductQuery";
import { ThemeType } from "../../Themes/types/types";
import { UserType } from "../../User/queries/useUserQuery";
import { nfcAxios, nfcUrls } from "./nfcService";

export type NFCType = {
  id: number;
  code: string;
  created_at: string;
  updated_at: string;
  url: string;
  product?: ProductType;
  product_id: null | Record<string, unknown>;
  theme?: ThemeType;
  theme_id: null | Record<string, unknown>;
  user?: UserType;
  user_id: number;
  lastLinkedAt: null | string;
  note: string;
};

const useNFCQuery = (id?: number | string, swrConfig?: SWRConfiguration) =>
  useSWRAxios<NFCType>(
    id
      ? {
          url: nfcUrls.detail(id),
          method: "GET",
        }
      : null,
    nfcAxios,
    swrConfig,
  );

export default useNFCQuery;
