import { Helmet } from "react-helmet-async";
import { Switch, useRouteMatch } from "react-router-dom";
import TitledRoute from "../../../components/Route/TitledRoute";
import ThemesList from "./ThemesList";

const ThemesRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet defaultTitle="Themes | Biobio" titleTemplate="%s - Themes | Biobio" />
      <Switch>
        <TitledRoute title="Theme List" path={path}>
          <ThemesList />
        </TitledRoute>
      </Switch>
    </>
  );
};

export default ThemesRoutes;
