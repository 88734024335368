import useUsersQuery, { UsersRes } from "../queries/useUsersQuery";
import MuiAutocomplete, {
  MuiAutocompleteProps,
} from "../../../components/UncontrolledForm/MuiAutocomplete";

export type UserAutoCompleteOption = UsersRes["data"][number];

type UsersAutoCompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = Omit<
  MuiAutocompleteProps<UserAutoCompleteOption, Multiple, DisableClearable, false>,
  "options"
>;

const UsersAutoComplete = <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
>({
  ...rest
}: UsersAutoCompleteProps<Multiple, DisableClearable>): JSX.Element => {
  const { data, isValidating, setParams } = useUsersQuery({
    defaultConfig: {
      limit: 20,
    },
  });

  const options = data?.data ?? [];

  return (
    <MuiAutocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => `${option.username} - ${option.name}`}
      onInputChange={(_e, newInputValue) => {
        setParams("search", newInputValue ? `username|${newInputValue}` : undefined);
      }}
      filterOptions={(x) => x}
      loading={isValidating}
      loadingText="Loading..."
    />
  );
};

export default UsersAutoComplete;
