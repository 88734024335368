import clsx from "clsx";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { config } from "react-spring";
import Fade from "../Transitions/Fade/Fade";

type BackdropProps = {
  in: boolean;
  onClick?: () => void;
  className?: string;
};

const bodyEl = document.body;

const Backdrop = ({ in: inProp, onClick, className }: BackdropProps): JSX.Element => {
  useEffect(() => {
    if (!inProp) return;
    document.body.classList.add("overflow-hidden");
    return () => {
      const backdropEl = document.getElementsByClassName("drawer-overlay");
      if (backdropEl.length === 1) document.body.classList.remove("overflow-hidden");
    };
  }, [inProp]);

  return ReactDOM.createPortal(
    <Fade
      in={inProp}
      className={clsx("drawer-overlay", className)}
      onClick={onClick}
      config={config.stiff}
    />,
    bodyEl,
  );
};

export default Backdrop;
