import { Helmet } from "react-helmet-async";
import { Link, LinkProps } from "react-router-dom";
import Progress from "../../DataTable/Progress/Progress";
import AngleLeftIcon from "../../Icons/Stockholm/Navigation/AngleLeftIcon";
import CloseIcon from "../../Icons/Stockholm/Navigation/CloseIcon";
import DrawerHeaderButton from "./DrawerHeaderButton";

type DrawerHeaderProps = {
  /** drawer close button click handler */
  onClose?: () => void;
  /** title */
  title?: string;
  /** drawer tools */
  tools?: JSX.Element;
  /** show loading bar */
  loading?: boolean;
  /** back url */
  backTo?: LinkProps["to"];
};

const DrawerHeader = ({
  onClose,
  title,
  tools,
  loading,
  backTo,
}: DrawerHeaderProps): JSX.Element => {
  return (
    <div className="card-header pe-5 position-relative">
      <Helmet title={title} />
      {/* begin::Title */}
      <div className="card-title">
        {backTo && (
          <Link to={backTo}>
            <DrawerHeaderButton
              icon={<AngleLeftIcon size="1" color="gray-800" />}
              className="btn-active-color-primary"
              onClick={onClose}
            />
          </Link>
        )}
        <div className="d-flex justify-content-center flex-column me-3">
          <div className="fs-4 fw-bolder text-gray-900 me-1 lh-1">{title}</div>
        </div>
      </div>
      {/* end::Title */}
      {/* begin::Card toolbar */}
      <div className="card-toolbar">
        {tools}
        <DrawerHeaderButton
          className="btn-active-light-dark"
          icon={<CloseIcon size="2" />}
          onClick={onClose}
          noMe
          tooltipProps={{
            title: "Close",
          }}
        />
      </div>
      {/* end::Card toolbar */}
      <div className="position-absolute bottom-0 start-0 end-0">
        <Progress in={loading} />
      </div>
    </div>
  );
};

export default DrawerHeader;
