import clsx from "clsx";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "../../../../components/DataTable/DataTable";
import PlusIcon from "../../../../components/Icons/Stockholm/Navigation/PlusIcon";
import { Tbody, Tr, Td } from "../../../../components/Table";
import useNFCsQuery, { NFCsRes } from "../../queries/useNFCsQuery";
import { GetRequest } from "../../../../hooks/useDataTable";
import usePages from "../../../../hooks/usePages";
import { useSWRAxios } from "../../../../hooks/useSWRAxios";
import { nfcAxios } from "../../queries/nfcService";
import ExportQRCodeButton from "./ExportQRCodeButton";
import { NFCType } from "../../queries/useNFCQuery";
import MuiCheckbox from "../../../../components/UncontrolledForm/MuiCheckbox";
import MuiSwitch from "../../../../components/UncontrolledForm/MuiSwitch";
import Collapse from "../../../../components/Transitions/Collapse/Collapse";
import UpdateBulkNFCProduct from "./UpdateBulkNFCProduct";
import NFCDetail from "../NFCDetail/NFCDetail";
import UsersAutoComplete from "../../../User/utilities/UsersAutoComplete";
import { InputAdornment } from "@mui/material";
import FilterIcon from "../../../../components/Icons/Stockholm/Text/FilterIcon";
import CopyTooltip from "../../../../components/Tooltip/CopyTooltip";
import MuiTextField from "../../../../components/UncontrolledForm/MuiTextField";
import SearchIcon from "../../../../components/Icons/Stockholm/General/SearchIcon";
import ProductsAutoComplete from "../../../Products/utilities/ProductsAutoComplete";
import NFCUpdate from "../NFCUpdate/NFCUpdate";
import NA from "../../../../components/NoAnswer/NA";
import NFCCreateBulk from "../NFCCreateBulk/NFCCreateBulk";
import ScanQRCodeButton from "./ScanQRCodeButton";
import RefreshButton from "../../../../components/Button/RefreshButton";
import MuiSelectField from "../../../../components/UncontrolledForm/MuiSelectField";
import UpdateBulkNFCTheme from "./UpdateBulkNFCTheme";

const NFCList = (): JSX.Element => {
  const { path, url } = useRouteMatch();
  const {
    config,
    setConfig,
    setParams,
    data,
    request,
    getRequest,
    isValidating,
    mutate,
    params: { url: paramsUrl },
  } = useNFCsQuery({
    defaultConfig: {
      sort: { key: "lastLinkedAt", isAsc: false },
    },
  });

  const pages = usePages(data?.totalData ?? 0, config.page, config.limit, {
    includeFirstAndLast: true,
  });

  const [isShowingSelectedData, setShowingSelectedData] = useState<boolean>(false);
  const [updateBulkType, setUpdateBulkType] = useState<"product" | "theme" | "">("");
  const [selectedData, setSelectedData] = useState<NFCType[]>([]);
  const handleSelect = useCallback(
    (row: NFCType) => {
      setSelectedData((state) => {
        const newState = [...state];
        const existIndex = newState.findIndex((x) => x?.id === row.id);

        if (existIndex > -1) {
          newState.splice(existIndex, 1);
          if (newState.length === 0) setShowingSelectedData(false);
        } else {
          newState.push(row);
        }
        return newState;
      });
    },
    [setSelectedData, setShowingSelectedData],
  );

  const dataSource = data?.data ?? [];
  const isAllChecked = useMemo(() => {
    const d = isShowingSelectedData ? selectedData : dataSource;
    if (!d.length) return false;

    const checked = d.every((data) => {
      return selectedData.find((selected) => selected.id === data.id);
    });
    return checked;
  }, [selectedData, dataSource, isShowingSelectedData]);

  const handleSelectAll = useCallback(() => {
    setSelectedData((state) => {
      // if showing selected data, click check all will remove all
      if (isShowingSelectedData) {
        setShowingSelectedData(false);
        return [];
      }

      const newState = [...state];
      dataSource.forEach((data) => {
        const foundIndex = newState.findIndex((selected) => selected.id === data.id);

        if (isAllChecked) newState.splice(foundIndex, 1);
        if (!isAllChecked && foundIndex === -1) newState.push(data);
      });
      return newState;
    });
  }, [isAllChecked, dataSource, isShowingSelectedData]);

  const searchByQRCodeResult = (text: string) => {
    setParams("url", text);
  };

  return (
    <div className="card">
      <Helmet title="NFC List" />
      <NFCCreateBulk path="/create-bulk" onClose={mutate} />
      <NFCDetail path="/detail/:id" onClose={mutate} url={url} />
      <NFCUpdate path="/update/:id" onClose={mutate} url={url} />
      <div className="card-header border-0 pt-6">
        <div className="card-title flex-fill me-3">
          <div className="row g-3 flex-fill">
            <div className="col-12 col-sm-6 col-md-4">
              <MuiTextField
                margin="none"
                hiddenLabel
                placeholder="Search by URL"
                onChange={({ currentTarget: { value } }) =>
                  setParams("url", value ? value : undefined)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon size="2" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <ScanQRCodeButton onScan={searchByQRCodeResult} />
                    </InputAdornment>
                  ),
                }}
                value={paramsUrl ?? ""}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <UsersAutoComplete
                placeholder="Filter Linked to"
                onChange={(_e, v) => {
                  setParams("userId", v ? v.id.toString() : undefined);
                }}
                textFieldProps={{
                  hiddenLabel: true,
                  margin: "none",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterIcon size="2" />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <ProductsAutoComplete
                placeholder="Filter Product"
                onChange={(_e, v) => {
                  setParams("productId", v ? v.id.toString() : undefined);
                }}
                textFieldProps={{
                  hiddenLabel: true,
                  margin: "none",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterIcon size="2" />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
            <ExportQRCodeButton onSuccess={mutate} params={request?.params} />
            <Link to={path + "/create-bulk"} className="btn btn-primary">
              <PlusIcon size="2" />
              Create NFC
            </Link>
            <RefreshButton onClick={() => mutate()} loading={isValidating} />
          </div>
        </div>
      </div>
      <Collapse in={selectedData.length > 0}>
        <div className="px-9 d-flex justify-content-between align-items-stretch pt-5 pb-2">
          <div className="card-title mb-0">
            <div className="d-flex flex-wrap">
              <div>
                <MuiSwitch
                  label={`Show ${selectedData.length} selected NFC`}
                  formControlProps={{
                    margin: "none",
                  }}
                  onChange={(e) => setShowingSelectedData(e.target.checked)}
                  value={isShowingSelectedData}
                />
              </div>
              <div className="me-5">
                <MuiSelectField
                  hiddenLabel
                  margin="none"
                  placeholder="Choose Action"
                  value={updateBulkType}
                  onChange={({ target: { value } }) =>
                    setUpdateBulkType(value as typeof updateBulkType)
                  }
                  options={[
                    {
                      children: "Update Product",
                      value: "product",
                    },
                    {
                      children: "Update Theme",
                      value: "theme",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            {updateBulkType === "product" && (
              <UpdateBulkNFCProduct items={selectedData} onSuccess={mutate} />
            )}
            {updateBulkType === "theme" && (
              <UpdateBulkNFCTheme items={selectedData} onSuccess={mutate} />
            )}
          </div>
        </div>
      </Collapse>
      <div className="card-body pt-0">
        <DataTable
          columns={[
            { label: "No." },
            {
              label: (
                <MuiCheckbox
                  label=""
                  formControlProps={{
                    margin: "none",
                  }}
                  checked={isAllChecked}
                  onChange={handleSelectAll}
                />
              ),
              className: "pe-0 py-0",
            },
            { label: "Code" },
            { label: "Product", key: "product_id" },
            { label: "Note", key: "note" },
            { label: "Linked to", key: "user" },
            { label: "Linked at", key: "lastLinkedAt" },
            { label: "Created at", key: "created_at" },
          ]}
          loading={isValidating}
          total={data?.totalData ?? 0}
          config={config}
          setConfig={setConfig}
        >
          {isShowingSelectedData ? (
            <SelectedDataBody selectedData={selectedData} handleSelect={handleSelect} />
          ) : (
            pages.map((page) => (
              <Body
                key={page}
                request={getRequest(page)}
                active={page === config.page}
                selectedData={selectedData}
                handleSelect={handleSelect}
              />
            ))
          )}
        </DataTable>
      </div>
    </div>
  );
};

type BodyProps = {
  /** axios config request */
  request: ReturnType<GetRequest>;
  /** is showing */
  active: boolean;
  /** selected NFC list */
  selectedData: NFCType[];
  /** select & deselect data toggler */
  handleSelect: (row: NFCType) => void;
} & React.ComponentPropsWithoutRef<"tbody">;

const Body = ({
  request,
  active,
  className,
  selectedData,
  handleSelect,
}: BodyProps): JSX.Element => {
  const { data } = useSWRAxios<NFCsRes>(request, nfcAxios);
  const startNo = useMemo(() => {
    const page = request.params.page ?? 0;
    const limit = request.params.limit ?? 0;
    return (page - 1) * limit + 1;
  }, [request]);

  return (
    <Tbody className={clsx("text-gray-600 fw-bold", !active && "d-none", className)}>
      {data?.data.map((d, i) => (
        <DataRow
          key={d.id}
          no={startNo + i}
          d={d}
          selectedData={selectedData}
          handleSelect={handleSelect}
        />
      ))}
    </Tbody>
  );
};

type SelectedDataBodyProps = {
  /** selected NFC list */
  selectedData: NFCType[];
  /** select & deselect data toggler */
  handleSelect: (row: NFCType) => void;
};

const SelectedDataBody = ({ selectedData, handleSelect }: SelectedDataBodyProps) => {
  return (
    <Tbody className="text-gray-600 fw-bold">
      {selectedData.map((d, i) => (
        <DataRow
          key={d.id}
          no={i + 1}
          d={d}
          selectedData={selectedData}
          handleSelect={handleSelect}
        />
      ))}
    </Tbody>
  );
};

type DataRowProps = {
  no: number;
  d: NFCType;
} & SelectedDataBodyProps;

const DataRow = ({ selectedData, handleSelect, no, d }: DataRowProps) => {
  const { url } = useRouteMatch();
  return (
    <Tr>
      <Td>{no}</Td>
      <Td width="50" className="pe-0">
        <MuiCheckbox
          label=""
          formControlProps={{
            margin: "none",
          }}
          checked={selectedData.findIndex((x) => x?.id === d.id) > -1}
          onChange={() => handleSelect(d)}
        />
      </Td>
      <Td>
        <CopyTooltip url={d.url}>
          <Link to={`${url}/detail/${d.id}`} className="text-gray-800 text-hover-primary fw-bolder">
            {d.code}
          </Link>
        </CopyTooltip>
      </Td>
      <Td className="text-gray-800">
        <Link
          to={`/products/detail/${d.product_id}`}
          className="text-gray-800 text-hover-primary fw-bolder"
        >
          {d.product?.name ?? d.product_id ?? <NA />}
        </Link>
      </Td>
      <Td>{d.note ? d.note : <NA />}</Td>
      {d.user_id ? (
        <Td className="text-gray-800">
          <Link
            to={`/users/detail/${d.user_id}`}
            className="text-gray-800 text-hover-primary fw-bolder"
          >
            {d.user?.username ?? d.user_id}
          </Link>
        </Td>
      ) : (
        <Td>
          <NA />
        </Td>
      )}
      <Td>{d.lastLinkedAt ? dayjs(d.lastLinkedAt).format("DD MMM YYYY HH:mm:ss") : <NA />}</Td>
      <Td>{d.created_at ? dayjs(d.created_at).format("DD MMM YYYY HH:mm:ss") : <NA />}</Td>
    </Tr>
  );
};

export default NFCList;
