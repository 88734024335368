import { SWRConfiguration } from "swr";
import { userAxios, userUrls } from "./userService";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import { UserType } from "./useUserQuery";

export type LoggedInUserAdditionalDataType = {
  access: string[];
};

export type MeRes = {
  data: {
    user: UserType & LoggedInUserAdditionalDataType;
  };
};

const useMeQuery = (token: string | undefined, swrConfig?: SWRConfiguration) =>
  useSWRAxios<MeRes>(
    token
      ? {
          url: userUrls.me,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null,
    userAxios,
    swrConfig,
  );

export default useMeQuery;
