import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BreadcrumbItemType } from "../../components/Layouts/Main/Breadcrumbs";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { SystemRes } from "../../hooks/queries/system/useSystemQuery";

type ConfigSlice = {
  /** aside drawer open / close */
  isAsideOpen: boolean;
  /** aside drawer open / close */
  isAsideMinimized: boolean;
  /** layout header title */
  headerTitle?: string;
  /** layout header breadcrumbs */
  headerBreadcrumbs?: BreadcrumbItemType[];
  /** system info variable */
  systemInfo?: SystemRes;
};

const initialState: ConfigSlice = {
  isAsideOpen: false,
  isAsideMinimized: false,
  headerTitle: "",
  headerBreadcrumbs: [],
  systemInfo: undefined,
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    /** toggle aside for < desktop screen size */
    toggleAsideOpen: (state, action: PayloadAction<boolean | undefined>) => {
      state.isAsideOpen = action.payload ?? !state.isAsideOpen;
    },
    /** toggle aside minimized */
    toggleAsideMinimize: (state, action: PayloadAction<boolean | undefined>) => {
      state.isAsideMinimized = action.payload ?? !state.isAsideMinimized;
    },
    /** setter for header title */
    setHeader: (
      state,
      {
        payload: { title, breadcrumbs },
      }: PayloadAction<{ title?: string; breadcrumbs?: BreadcrumbItemType[] }>,
    ) => {
      state.headerTitle = title;
      state.headerBreadcrumbs = breadcrumbs;
    },
    /** clear header title */
    clearHeader: (state) => {
      state.headerTitle = "";
      state.headerBreadcrumbs = [];
    },
    setSystemInfo: (state, { payload: systemInfo }: PayloadAction<SystemRes>) => {
      state.systemInfo = systemInfo;
    },
  },
});

export const configActions = configSlice.actions;
export const configReducer = persistReducer(
  {
    key: "configSlice",
    version: 1,
    storage,
    whitelist: ["isAsideOpen", "isAsideMinimized", "systemInfo"],
  },
  configSlice.reducer,
);
