import { useMemo } from "react";
import { animated, useTransition } from "react-spring";
import type { SpringConfig } from "react-spring";

interface FadeProps extends React.ComponentPropsWithoutRef<"div"> {
  in: boolean;
  slide?: "up" | "down" | "left" | "right";
  config?: SpringConfig;
}

const range = 20;

const Fade = ({ in: inProp, slide, children, config, ...props }: FadeProps): JSX.Element => {
  const slideFromAndLeave = useMemo(() => {
    switch (slide) {
      case "up":
        return { y: range };
      case "down":
        return { y: -range };
      case "left":
        return { x: range };
      case "right":
        return { x: -range };
      default:
        return {};
    }
  }, [slide]);

  const slideEnter = useMemo(() => {
    switch (slide) {
      case "up":
      case "down":
        return { y: 0 };
      case "left":
      case "right":
        return { x: 0 };
      default:
        return {};
    }
  }, [slide]);

  const transitions = useTransition(inProp, {
    config,
    from: { opacity: 0, ...slideFromAndLeave },
    enter: { opacity: 1, ...slideEnter },
    leave: { opacity: 0, ...slideFromAndLeave },
    reverse: inProp,
  });

  return transitions(
    (styles, show) =>
      show && (
        <animated.div style={styles} {...props}>
          {children}
        </animated.div>
      ),
  );
};

export default Fade;
