interface USFlagIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  size?: number;
}

const USFlagIcon = ({ size = 15, ...props }: USFlagIconProps): JSX.Element => {
  return (
    <svg viewBox="0 0 512 512" width={size} height={size} {...props}>
      <rect y="0.235" fill="#F5F5F5" width="511.53" height="511.53" />
      <g>
        <rect y="0.235" fill="#FF4B55" width="512" height="39.34" />
        <rect y="157.63" fill="#FF4B55" width="512" height="39.34" />
        <rect y="78.935" fill="#FF4B55" width="512" height="39.34" />
        <rect y="236.32" fill="#FF4B55" width="512" height="39.34" />
        <rect y="393.71" fill="#FF4B55" width="512" height="39.34" />
        <rect y="472.41" fill="#FF4B55" width="512" height="39.34" />
        <rect y="315.02" fill="#FF4B55" width="512" height="39.34" />
      </g>
      <rect y="0.235" fill="#41479B" width="275.44" height="275.44" />
      <g>
        <path
          fill="#F5F5F5"
          d="M35.219,20.814l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C33.779,19.99,34.945,19.99,35.219,20.814z"
        />
        <path
          fill="#F5F5F5"
          d="M35.219,73.902l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C33.779,73.077,34.945,73.077,35.219,73.902z"
        />
        <path
          fill="#F5F5F5"
          d="M35.219,132.78l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.632l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.632l6.708-0.052l2.122-6.364C33.779,131.956,34.945,131.956,35.219,132.78z"
        />
        <path
          fill="#F5F5F5"
          d="M35.219,186.876l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C33.779,186.052,34.945,186.052,35.219,186.876z"
        />
        <path
          fill="#F5F5F5"
          d="M35.219,236.102l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.631l6.708-0.052l2.122-6.364C33.779,235.278,34.945,235.278,35.219,236.102z"
        />
        <path
          fill="#F5F5F5"
          d="M61.058,45.953l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   L49.98,54c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C59.618,45.129,60.784,45.129,61.058,45.953z"
        />
        <path
          fill="#F5F5F5"
          d="M61.058,104.832l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C59.618,104.008,60.784,104.008,61.058,104.832z"
        />
        <path
          fill="#F5F5F5"
          d="M61.058,160.771l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C59.618,159.948,60.784,159.948,61.058,160.771z"
        />
        <path
          fill="#F5F5F5"
          d="M61.058,211.007l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C59.618,210.182,60.784,210.182,61.058,211.007z"
        />
        <path
          fill="#F5F5F5"
          d="M86.898,20.814l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C85.458,19.99,86.623,19.99,86.898,20.814z"
        />
        <path
          fill="#F5F5F5"
          d="M86.898,73.902l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C85.458,73.077,86.623,73.077,86.898,73.902z"
        />
        <path
          fill="#F5F5F5"
          d="M86.898,132.78l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.632l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.632l6.708-0.052l2.122-6.364C85.458,131.956,86.623,131.956,86.898,132.78z"
        />
        <path
          fill="#F5F5F5"
          d="M86.898,186.876l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.456-3.902l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C85.458,186.052,86.623,186.052,86.898,186.876z"
        />
        <path
          fill="#F5F5F5"
          d="M86.898,236.102l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.631l6.708-0.052l2.122-6.364C85.458,235.278,86.623,235.278,86.898,236.102z"
        />
        <path
          fill="#F5F5F5"
          d="M112.737,45.953l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   L101.659,54c-0.698-0.516-0.339-1.624,0.53-1.631l6.711-0.052l2.122-6.364C111.297,45.129,112.462,45.129,112.737,45.953z"
        />
        <path
          fill="#F5F5F5"
          d="M112.737,104.832l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C111.297,104.008,112.462,104.008,112.737,104.832z"
        />
        <path
          fill="#F5F5F5"
          d="M112.737,160.771l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C111.297,159.948,112.462,159.948,112.737,160.771z"
        />
        <path
          fill="#F5F5F5"
          d="M112.737,211.007l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C111.297,210.182,112.462,210.182,112.737,211.007z"
        />
        <path
          fill="#F5F5F5"
          d="M138.576,20.814l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C137.136,19.99,138.301,19.99,138.576,20.814z"
        />
        <path
          fill="#F5F5F5"
          d="M138.576,73.902l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C137.136,73.077,138.301,73.077,138.576,73.902z"
        />
        <path
          fill="#F5F5F5"
          d="M138.576,132.78l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.632l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.632l6.708-0.052l2.122-6.364C137.136,131.956,138.301,131.956,138.576,132.78z"
        />
        <path
          fill="#F5F5F5"
          d="M138.576,186.876l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C137.136,186.052,138.301,186.052,138.576,186.876z"
        />
        <path
          fill="#F5F5F5"
          d="M138.576,236.102l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.631l6.708-0.052l2.122-6.364C137.136,235.278,138.301,235.278,138.576,236.102z"
        />
        <path
          fill="#F5F5F5"
          d="M164.415,45.953l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   L153.337,54c-0.698-0.516-0.339-1.624,0.529-1.631l6.708-0.052l2.122-6.364C162.975,45.129,164.14,45.129,164.415,45.953z"
        />
        <path
          fill="#F5F5F5"
          d="M164.415,104.832l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.529-1.631l6.708-0.052l2.122-6.364C162.975,104.008,164.14,104.008,164.415,104.832z"
        />
        <path
          fill="#F5F5F5"
          d="M164.415,160.771l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.529-1.631l6.708-0.052l2.122-6.364C162.975,159.948,164.14,159.948,164.415,160.771z"
        />
        <path
          fill="#F5F5F5"
          d="M164.415,211.007l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.529-1.631l6.708-0.052l2.122-6.364C162.975,210.182,164.14,210.182,164.415,211.007z"
        />
        <path
          fill="#F5F5F5"
          d="M190.254,20.814l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C188.814,19.99,189.979,19.99,190.254,20.814z"
        />
        <path
          fill="#F5F5F5"
          d="M190.254,73.902l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C188.814,73.077,189.979,73.077,190.254,73.902z"
        />
        <path
          fill="#F5F5F5"
          d="M190.254,132.78l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.632l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.632l6.708-0.052l2.122-6.364C188.814,131.956,189.979,131.956,190.254,132.78z"
        />
        <path
          fill="#F5F5F5"
          d="M190.254,186.876l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C188.814,186.052,189.979,186.052,190.254,186.876z"
        />
        <path
          fill="#F5F5F5"
          d="M190.254,236.102l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.631l6.708-0.052l2.122-6.364C188.814,235.278,189.979,235.278,190.254,236.102z"
        />
        <path
          fill="#F5F5F5"
          d="M216.094,45.953l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   L205.016,54c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C214.653,45.129,215.819,45.129,216.094,45.953z"
        />
        <path
          fill="#F5F5F5"
          d="M216.094,104.832l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C214.653,104.008,215.819,104.008,216.094,104.832z"
        />
        <path
          fill="#F5F5F5"
          d="M216.094,160.771l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C214.653,159.948,215.819,159.948,216.094,160.771z"
        />
        <path
          fill="#F5F5F5"
          d="M216.094,211.007l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C214.653,210.182,215.819,210.182,216.094,211.007z"
        />
        <path
          fill="#F5F5F5"
          d="M241.933,20.814l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C240.492,19.99,241.658,19.99,241.933,20.814z"
        />
        <path
          fill="#F5F5F5"
          d="M241.933,73.902l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C240.492,73.077,241.658,73.077,241.933,73.902z"
        />
        <path
          fill="#F5F5F5"
          d="M241.933,132.78l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.632l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.632l6.708-0.052l2.122-6.364C240.492,131.956,241.658,131.956,241.933,132.78z"
        />
        <path
          fill="#F5F5F5"
          d="M241.933,186.876l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.624,0.53-1.631l6.708-0.052l2.122-6.364C240.492,186.052,241.658,186.052,241.933,186.876z"
        />
        <path
          fill="#F5F5F5"
          d="M241.933,236.102l2.122,6.364l6.708,0.052c0.868,0.007,1.229,1.115,0.53,1.631l-5.396,3.985   l2.023,6.396c0.262,0.828-0.68,1.513-1.388,1.008l-5.458-3.901l-5.458,3.901c-0.706,0.506-1.649-0.18-1.388-1.008l2.023-6.396   l-5.396-3.985c-0.698-0.516-0.339-1.625,0.53-1.631l6.708-0.052l2.122-6.364C240.492,235.278,241.658,235.278,241.933,236.102z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default USFlagIcon;
