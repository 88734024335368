import { toast } from "react-toastify";
import { DrawerHeaderButton } from "../../../../components/Drawer/DrawerCard";
import DisableIcon from "../../../../components/Icons/Stockholm/General/DisableIcon";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";
import { userAxios, userUrls } from "../../queries/userService";
import { UserType } from "../../queries/useUserQuery";

type UserDetailBlockUnblockButtonProps = {
  user: UserType;
  onSuccess: () => void;
};

const UserDetailBlockUnblockButton = ({
  user,
  onSuccess,
}: UserDetailBlockUnblockButtonProps): JSX.Element => {
  return user.isBlocked ? (
    <UnblockButton user={user} onSuccess={onSuccess} />
  ) : (
    <BlockButton user={user} onSuccess={onSuccess} />
  );
};

const UnblockButton = ({ user, onSuccess }: UserDetailBlockUnblockButtonProps) => {
  const [state, submit] = useAxiosAsync(async () => {
    try {
      const res = await userAxios.post(userUrls.unblock(user.id));
      toast.success("User Unblocked");
      onSuccess();
      return res;
    } catch (error) {
      toast.error("Failed to Unblock User");
      throw error;
    }
  }, []);

  return (
    <DrawerHeaderButton
      icon={<DisableIcon size="2" />}
      tooltipProps={{
        title: "Unblock",
      }}
      className="btn-danger"
      onClick={submit}
      loading={state.loading}
    />
  );
};

const BlockButton = ({ user, onSuccess }: UserDetailBlockUnblockButtonProps) => {
  const [state, submit] = useAxiosAsync(async () => {
    try {
      const res = await userAxios.post(userUrls.block(user.id));
      toast.success("User Blocked");
      onSuccess();
      return res;
    } catch (error) {
      toast.error("Failed to Block User");
      throw error;
    }
  }, []);

  return (
    <DrawerHeaderButton
      icon={<DisableIcon size="2" />}
      tooltipProps={{
        title: "Block",
      }}
      className="btn-active-light-danger"
      onClick={submit}
      loading={state.loading}
    />
  );
};

export default UserDetailBlockUnblockButton;
