import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MuiTextField from "../../../components/ControlledForm/MuiTextField";
import MuiSelectField from "../../../components/ControlledForm/MuiSelectField";
import FilePond from "../../../components/ControlledForm/FilePond";
import useProductTypesQuery from "../queries/useProductTypesQuery";

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  color: yup.string().required(),
  picture: yup.mixed(),
});

export type ProductFormValues = {
  name: string;
  type: string;
  color: string;
  picture?: File[];
};

type ProductFormProps = {
  /** form id to submit when submit button clicked */
  formId: string;
  /** form on submit */
  onSubmit: (values: ProductFormValues) => void;
  /** form default values */
  defaultValues: Partial<ProductFormValues>;
  /** filepond init files */
  initialPictureSrc?: string;
};

const ProductForm = ({
  defaultValues,
  onSubmit,
  formId,
  initialPictureSrc,
}: ProductFormProps): JSX.Element => {
  const { data } = useProductTypesQuery();
  const productTypeOptions = data?.data.map((x) => ({ children: x, value: x })) ?? [];

  const { handleSubmit, control } = useForm<ProductFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
      <MuiTextField
        control={control}
        label="Name"
        name="name"
        placeholder="e.g Rainbow Tag"
        defaultValue={defaultValues.name ?? ""}
      />
      <MuiSelectField
        control={control}
        label="Type"
        name="type"
        placeholder="Select Type"
        defaultValue={defaultValues.type ?? ""}
        options={productTypeOptions}
      />
      <MuiTextField
        control={control}
        label="Color"
        name="color"
        placeholder="e.g Rainbow"
        defaultValue={defaultValues.color ?? ""}
      />
      <FilePond
        control={control}
        name="picture"
        allowMultiple={false}
        label="Picture"
        maxFileSize="10MB"
        initialPictureSrc={initialPictureSrc}
      />
    </form>
  );
};

export default ProductForm;
