import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import SearchIcon from "../../../components/Icons/Stockholm/General/SearchIcon";
import PlusIcon from "../../../components/Icons/Stockholm/Navigation/PlusIcon";
import { Tbody, Tr, Td } from "../../../components/Table";
import useProductsQuery, { ProductsRes } from "../queries/useProductsQuery";
import { GetRequest } from "../../../hooks/useDataTable";
import usePages from "../../../hooks/usePages";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import ProductCreate from "./ProductCreate";
import ProductUpdate from "./ProductUpdate";
import { productAxios } from "../queries/productService";
import ProductDetail from "./ProductDetail";

const ProductList = (): JSX.Element => {
  const { path, url } = useRouteMatch();
  const { config, setConfig, setParams, data, getRequest, isValidating, mutate } =
    useProductsQuery();

  const pages = usePages(data?.totalData ?? 0, config.page, config.limit, {
    includeFirstAndLast: true,
  });

  return (
    <div className="card">
      <Helmet title="Product List" />
      <ProductCreate path="/create" onClose={mutate} />
      <ProductDetail path="/detail/:id" onClose={mutate} url={url} />
      <ProductUpdate path="/update/:id" onClose={mutate} />
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <SearchIcon size="1" className="position-absolute ms-6" />
            <input
              type="text"
              className="form-control form-control-sm form-control-solid w-250px ps-14"
              placeholder="Search"
              onChange={(e) => setParams("search", e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
            <Link to={path + "/create"} className="btn btn-primary">
              <PlusIcon size="2" />
              Create Product
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <DataTable
          columns={[
            { label: "No." },
            { label: "Created at", key: "createdAt" },
            { label: "Name" },
            { label: "Color" },
            { label: "Type" },
            { label: "Picture" },
          ]}
          loading={isValidating}
          total={data?.totalData ?? 0}
          config={config}
          setConfig={setConfig}
        >
          {pages.map((page) => (
            <Body key={page} request={getRequest(page)} active={page === config.page} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

type BodyProps = {
  /** axios config request */
  request: ReturnType<GetRequest>;
  /** is showing */
  active: boolean;
} & React.ComponentPropsWithoutRef<"tbody">;

const Body = ({ request, active, className }: BodyProps): JSX.Element => {
  const { url } = useRouteMatch();
  const { data } = useSWRAxios<ProductsRes>(request, productAxios);
  const startNo = useMemo(() => {
    const page = request.params.page ?? 0;
    const limit = request.params.limit ?? 0;
    return (page - 1) * limit + 1;
  }, [request]);

  return (
    <Tbody className={clsx("text-gray-600 fw-bold", !active && "d-none", className)}>
      {data?.data.map((d, i) => (
        <Tr key={d.id}>
          <Td>{startNo + i}</Td>
          <Td>{dayjs(d.created_at).format("DD MMM YYYY")}</Td>
          <Td>
            <Link to={`${url}/detail/${d.id}`} className="text-gray-800 text-hover-primary">
              {d.name}
            </Link>
          </Td>
          <Td>{d.color}</Td>
          <Td>{d.type}</Td>
          <Td className="text-gray-800">
            <a href={d.picture} target="_blank" rel="noopener noreferrer">
              <img className="img-fluid" width="40" src={d.picture} alt="" />
            </a>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default ProductList;
