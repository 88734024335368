import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MuiTextField from "../../../components/ControlledForm/MuiTextField";
import UncontrolledMuiTextField from "../../../components/UncontrolledForm/MuiTextField";
import MuiSelectField from "../../../components/ControlledForm/MuiSelectField";
import { ThemeType } from "../types/types";
import objectToArray from "../utilities/objectToArray";
import FilePond from "../../../components/ControlledForm/FilePond";
import { useCallback } from "react";
import arrayToObject from "../utilities/arrayToObject";
import MuiAutocomplete from "../../../components/ControlledForm/MuiAutocomplete";
import UncontrolledMuiAutocomplete from "../../../components/UncontrolledForm/MuiAutocomplete";
import { createFilterOptions } from "@mui/material";
import MuiSwitch from "../../../components/ControlledForm/MuiSwitch";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const filter = createFilterOptions<string>({});

const schema = yup.object().shape({
  name: yup.string().required(),
  partner: yup.string(),
  isPro: yup.boolean(),
  mode: yup.string().required("Scheme is a required field"),
  logo: yup
    .mixed()
    .test("is-required", "Logo is a required field", (value, context) =>
      context.options.context?.isLogoRequired && !value ? false : true,
    ),
  thumbnail: yup
    .mixed()
    .test("is-required", "Thumbnail is a required field", (value, context) =>
      context.options.context?.isThumbnailRequired && !value ? false : true,
    ),
  properties: yup.array(
    yup.object({
      key: yup.string().required("Required"),
      value: yup.string().required("Required"),
    }),
  ),
});

type ThemesFormType = {
  name: string;
  partner: string;
  isPro: boolean;
  mode: string;
  logo: File[];
  thumbnail: File[];
  properties: { key: string; value: string }[];
};

export type ThemesFormValues = {
  name: string;
  partner: string;
  isPro: number;
  mode: string;
  logo?: File;
  thumbnail?: File;
  properties: string;
};

type ThemesFormProps = {
  formId: string;
  theme?: ThemeType;
  onSubmit: (values: ThemesFormValues) => void;
};

const ThemesForm = ({ formId, theme, onSubmit }: ThemesFormProps): JSX.Element => {
  const { handleSubmit, control, watch } = useForm<ThemesFormType>({
    resolver: yupResolver(schema),
    context: {
      isLogoRequired: !theme?.logo,
      isThumbnailRequired: !theme?.thumbnail,
    },
    defaultValues: {
      name: theme?.name ?? "",
      partner: theme?.partner ?? "",
      isPro: !!theme?.isPro ?? false,
      mode: theme?.mode ?? "",
      properties: theme?.properties ? objectToArray(theme.properties) : [],
    },
  });
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "properties",
  });
  const properties = watch("properties");
  const usedProperties = properties.map((x) => x.key);

  const propertiesOptions = [
    "btnBgColor",
    "btnTextColor",
    "bodyBgColor",
    "mainTextColor",
    "secondaryTextColor",
  ].filter((x) => usedProperties.indexOf(x) < 0);

  const finalizeValue = useCallback(
    (values: ThemesFormType) => {
      const data = {
        name: values.name,
        partner: values.partner,
        isPro: values.isPro ? 1 : 0,
        mode: values.mode,
        logo: values.logo ? values.logo[0] : undefined,
        thumbnail: values.thumbnail ? values.thumbnail[0] : undefined,
        properties: JSON.stringify(arrayToObject(values.properties)),
      };

      onSubmit(data);
    },
    [onSubmit],
  );

  return (
    <form id={formId} onSubmit={handleSubmit(finalizeValue)} noValidate>
      <Grid2 container columnSpacing={2} alignItems="center">
        <Grid2 xs={9}>
          <MuiTextField control={control} label="Name" name="name" placeholder="e.g RF Light" />
        </Grid2>
        <Grid2 xs={3}>
          <MuiSwitch
            control={control}
            name="isPro"
            label="Is Pro"
            formControlLabelProps={{ labelPlacement: "end" }}
          />
        </Grid2>
      </Grid2>
      <MuiTextField
        control={control}
        label="Partner"
        name="partner"
        placeholder="e.g Partner Name"
      />
      <MuiSelectField
        control={control}
        label="Scheme"
        name="mode"
        options={[
          {
            children: "Light",
            value: "Light",
          },
          {
            children: "Dark",
            value: "Dark",
          },
        ]}
      />
      <FilePond
        control={control}
        name="logo"
        allowMultiple={false}
        label="Logo"
        maxFileSize="1MB"
        initialPictureSrc={theme?.logo ?? ""}
      />
      <FilePond
        control={control}
        name="thumbnail"
        allowMultiple={false}
        label="Thumbnail"
        maxFileSize="1MB"
        initialPictureSrc={theme?.thumbnail ?? ""}
      />
      <div className="my-3">
        <div className="mb-3">Properties</div>
        <div className="row g-3 text-muted">
          <div className="col-6">Key</div>
          <div className="col-6">Value</div>
        </div>
        {fields.map((x, i) => (
          <div className="row g-3" key={x.id}>
            <div className="col-6">
              <MuiAutocomplete
                control={control}
                name={`properties.${i}.key`}
                shouldUnregister
                defaultValue=""
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option);
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(inputValue);
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                disableClearable
                options={propertiesOptions}
                onChange={(e) => {
                  e.preventDefault();
                  const inputToFocus = document.body.querySelector<HTMLInputElement>(
                    `input[name="properties.${i}.value"]`,
                  );
                  inputToFocus?.focus();
                }}
                textFieldProps={{
                  placeholder: "Input Key",
                  margin: "dense",
                  hiddenLabel: true,
                  InputProps: {
                    onKeyDown: (e) => {
                      switch (e.code) {
                        case "Backspace": {
                          if (e.currentTarget.value === "") {
                            e.preventDefault();
                            const inputToFocus = document.body.querySelector<HTMLInputElement>(
                              i === 0
                                ? `input[id="inputNewField"]`
                                : `input[name="properties.${i - 1}.value"]`,
                            );
                            inputToFocus?.focus();
                            remove(i);
                          }
                          break;
                        }
                        case "Enter": {
                          e.preventDefault();
                          const inputToFocus = document.body.querySelector<HTMLInputElement>(
                            `input[name="properties.${i}.value"]`,
                          );
                          inputToFocus?.focus();
                          break;
                        }
                        default: {
                          break;
                        }
                      }
                    },
                  },
                }}
              />
            </div>
            <div className="col-6">
              <MuiTextField
                control={control}
                name={`properties.${i}.value`}
                shouldUnregister
                defaultValue=""
                placeholder="Input Value"
                margin="dense"
                hiddenLabel
                InputProps={{
                  onKeyDown: (e) => {
                    switch (e.code) {
                      case "Backspace": {
                        if (e.currentTarget.value === "") {
                          e.preventDefault();
                          const inputToFocus = document.body.querySelector<HTMLInputElement>(
                            `input[name="properties.${i}.key"]`,
                          );
                          inputToFocus?.focus();
                        }
                        break;
                      }
                      case "Enter": {
                        e.preventDefault();
                        insert(i + 1, {
                          key: "",
                          value: "",
                        });
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  },
                }}
              />
            </div>
          </div>
        ))}
        <div className="row g-3">
          <div className="col-6">
            <UncontrolledMuiAutocomplete
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              options={propertiesOptions}
              placeholder="e.g btnBgColor"
              id="inputNewField"
              textFieldProps={{
                margin: "dense",
                hiddenLabel: true,
                InputProps: {
                  onKeyDown: (e) => {
                    switch (e.code) {
                      case "Backspace": {
                        if (e.currentTarget.value === "") {
                          e.preventDefault();
                          const inputToFocus = document.body.querySelector<HTMLInputElement>(
                            `input[name="properties.${fields.length - 1}.value"]`,
                          );
                          inputToFocus?.focus();
                        }
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  },
                },
              }}
              value={null}
              onChange={(e, value) => {
                e.preventDefault();
                if (value) {
                  append(
                    { key: value, value: "" },
                    {
                      focusName: `properties.${fields.length}.value`,
                    },
                  );
                }
              }}
            />
          </div>
          <div className="col-6">
            <UncontrolledMuiTextField
              placeholder="e.g #f7f7f9"
              margin="dense"
              hiddenLabel
              value=""
              onChange={(e) => {
                e.preventDefault();
                append(
                  { key: "", value: e.target.value },
                  {
                    focusName: `properties.${fields.length}.value`,
                  },
                );
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ThemesForm;
