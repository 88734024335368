import axios from "axios";
// import mock from "./roleMock";

export const roleAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

export const roleRequest = {
  list: {
    response: {
      data: [
        {
          idMasterRoles: "2",
          roleName: "Super Admin",
          created: "2021-03-02T04:00:53.428Z",
        },
        {
          idMasterRoles: "3",
          roleName: "Staff",
          created: "2021-03-03T03:35:21.400Z",
        },
      ],
      paging: {
        totalData: 3,
        totalPage: 1,
      },
    },
  },
  create: {
    response: {
      idMasterRoles: "4",
    },
  },
  update: {
    response: {
      idMasterRoles: "10",
    },
  },
};

export type CreateRoleResponse = typeof roleRequest.create.response;
export type UpdateRoleResponse = typeof roleRequest.update.response;

export const roleUrls = {
  list: "/roles",
  create: "/roles",
  detail: (idMasterRoles: number | string): string => `/roles/${idMasterRoles}`,
  update: (idMasterRoles: number | string): string => `/roles/${idMasterRoles}`,
};

// mock(roleAxios);
