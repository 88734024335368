import { useRef } from "react";
import { useState } from "react";
import { useSprings, a } from "react-spring";
import { useHoverDirty, useInterval, useMeasure } from "react-use";
import { useDrag } from "react-use-gesture";
import classes from "./carousel.module.scss";

type CarouselProps = {
  images: string[];
};

const Carousel = ({ images }: CarouselProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const isHovering = useHoverDirty(containerRef);
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const [springs, api] = useSprings(
    images.length,
    (i) => ({
      x: i * width,
      scale: width === 0 ? 0 : 1,
    }),
    [width],
  );
  const bind = useDrag(({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (active && distance > width / 2) {
      setActiveIndex((state) => {
        const nextIndex =
          state +
          (xDir > 0
            ? -1 // to left
            : 1); // to right
        const minIndex = 0;
        const maxIndex = images.length - 1;
        return nextIndex < minIndex ? minIndex : nextIndex > maxIndex ? maxIndex : nextIndex;
      });
      cancel();
    }
    api.start((i) => {
      const x = (i - activeIndex) * width + (active ? mx : 0);
      const scale = active ? 1 - distance / width / 2 : 1;
      return { x, scale };
    });
  });

  const dotSprings = useSprings(
    images.length,
    images.map((_image, key) => ({
      backgroundColor: key === activeIndex ? "rgb(0, 158, 247)" : "rgb(196, 196, 196)",
    })),
  );

  useInterval(
    () => {
      const nextIndex = (activeIndex + 1) % images.length;
      setActiveIndex(nextIndex);
      api.start((i) => {
        return {
          to: [
            {
              scale: 0.75,
            },
            {
              x: (i - nextIndex) * width,
            },
            {
              scale: 1,
            },
          ],
        };
      });
    },
    isHovering ? null : 5000,
  );

  return (
    <div className={classes.container} ref={containerRef}>
      <div ref={ref} className={classes.imgWrapper}>
        {springs.map(({ x, scale }, i) => (
          <a.div className={classes.page} {...bind()} key={i} style={{ x }}>
            <a.div style={{ scale, backgroundImage: `url(${images[i]})` }} />
          </a.div>
        ))}
      </div>
      <div className={classes.dotWrapper}>
        {dotSprings.map((style, dotIndex) => (
          <a.button
            key={dotIndex}
            onClick={() => {
              setActiveIndex(dotIndex);
              api.start((i) => {
                return {
                  to: [
                    {
                      scale: 0.75,
                    },
                    {
                      x: (i - dotIndex) * width,
                    },
                    {
                      scale: 1,
                    },
                  ],
                };
              });
            }}
            style={style}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
