import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";

type SplashScreenType = {
  setTitle: React.Dispatch<React.SetStateAction<string[]>>;
};

const SplashScreenContext = createContext<SplashScreenType>({
  setTitle: () => undefined,
});

const splashScreen = document.getElementById("splash-screen");

type SplashScreenProviderProps = { children: JSX.Element };

export const SplashScreenProvider = ({ children }: SplashScreenProviderProps): JSX.Element => {
  const [title, setTitle] = useState<string[]>([]);
  const visible = useMemo(() => title.length > 0, [title]);
  const latestTitle = useMemo(() => title[title.length - 1] ?? "", [title]);

  // This effect created to HIDE initiated splash screen from index.html
  useEffect(() => {
    splashScreen?.classList.add("hidden");
    // if (splashScreen?.classList.contains("init")) {
    //   splashScreen?.classList.remove("init");
    // }
  }, []);

  useEffect(() => {
    if (splashScreen && visible) {
      splashScreen?.classList.remove("hidden");

      return () => {
        splashScreen?.classList.add("hidden");
      };
    }
  }, [visible]);

  useEffect(() => {
    const splashScreenTitle = document.getElementById("splash-screen-title");
    if (splashScreenTitle) splashScreenTitle.innerText = latestTitle;
  }, [latestTitle]);

  return (
    <SplashScreenContext.Provider value={{ setTitle }}>{children}</SplashScreenContext.Provider>
  );
};

type SplashScreenProps = {
  title: string;
};

export function SplashScreen({ title }: SplashScreenProps): JSX.Element {
  const { setTitle } = useContext(SplashScreenContext);

  useEffect(() => {
    setTitle((prev) => [...prev, title]);

    return () => {
      setTitle((prev) => prev.filter((x) => x !== title));
    };
  }, [title]);
  return <Helmet title={title} />;
}
