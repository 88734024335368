import { Link, useRouteMatch } from "react-router-dom";
import {
  DrawerBody,
  DrawerCard,
  DrawerHeader,
  DrawerInfo,
  DrawerHeaderButton,
} from "../../components/Drawer/DrawerCard";
import withRouteDrawer, { WithRouteDrawerProps } from "../../components/Drawer/withRouteDrawer";
import EditIcon from "../../components/Icons/Stockholm/Interface/EditIcon";
import NA from "../../components/NoAnswer/NA";
import useRoleQuery from "../../hooks/queries/roles/useRoleQuery";
import useAccess from "../../hooks/useAccess";

const RoleDetail = ({ onClose }: WithRouteDrawerProps): JSX.Element => {
  const haveAccess = useAccess(["role.edit"]);
  const match = useRouteMatch<{ idMasterRoles: string }>();
  const idMasterRoles = match?.params.idMasterRoles;
  const { data, isValidating } = useRoleQuery(idMasterRoles);

  return (
    <DrawerCard>
      <DrawerHeader
        title="Role Detail"
        onClose={onClose}
        loading={isValidating}
        tools={
          <>
            {haveAccess[0] && (
              <Link to={`/user-management/roles/update/${idMasterRoles}`}>
                <DrawerHeaderButton
                  icon={<EditIcon size="5" />}
                  label="Edit"
                  className="btn-light-primary"
                />
              </Link>
            )}
          </>
        }
      />
      <DrawerBody>
        {data ? (
          <>
            <DrawerInfo label="Role Name">{data?.roleName}</DrawerInfo>
            <DrawerInfo label="Access">
              {data?.access && data?.access?.length > 0 ? (
                <ol className="mb-0 ps-5">
                  {data.access.map((x, key) => (
                    <li key={key} className="mb-1">
                      <div>{x}</div>
                    </li>
                  ))}
                </ol>
              ) : (
                <NA />
              )}
            </DrawerInfo>
          </>
        ) : null}
      </DrawerBody>
    </DrawerCard>
  );
};

export default withRouteDrawer(RoleDetail);
