import clsx from "clsx";
import { TableConfig } from "../../hooks/useDataTable";
import { HeadColumn } from "./DataTable";

type ThProps = {
  /** change sort handler */
  onSort: (sort: TableConfig["sort"]) => void;
  /** table configs */
  config: TableConfig;
  /** column */
  col: HeadColumn;
};

const SortableTh = ({
  onSort,
  config: { sort },
  col: { className, key, label, ...props },
}: ThProps): JSX.Element => {
  const isActive = typeof key !== "undefined" && sort?.key === key;
  const isAsc = sort?.isAsc;

  return (
    <th
      {...props}
      onClick={
        key
          ? () => {
              onSort?.({ key: key, isAsc: isActive && isAsc ? false : true });
            }
          : undefined
      }
      className={clsx(
        "align-middle",
        key ? "cursor-pointer sorting" : "sorting_disabled",
        isActive && (isAsc ? "sorting_asc" : "sorting_desc"),
        className,
      )}
    >
      {label}
    </th>
  );
};

export default SortableTh;
