import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useCallback } from "react";
import { useState } from "react";
import { useDebounce } from "react-use";
import { SWRConfiguration } from "swr";
import { useSWRAxios } from "./useSWRAxios";

export type TableConfig = {
  /** sorting configs */
  sort?: {
    /** sort key */
    key: string;
    /** is sort ascending */
    isAsc: boolean;
  };
  /** shows limit per page */
  limit: number;
  /** current page */
  page: number;
};

export type DataTableOptions<P> = {
  /** default config for table config */
  defaultConfig?: Partial<TableConfig>;
  /** service second argument */
  defaultParams?: P;
  /**
   * debounce timeout in ms
   * @default 300
   */
  debounceTimeout?: number;
};

export type GetRequest<Params = Record<string, string | number>> = (page?: number) => {
  params: ReturnType<PrepareToQueryParams> & Params;
} & Omit<AxiosRequestConfig, "baseURL" | "method" | "data" | "params">;

type PrepareToQueryParams = (config: TableConfig) => {
  /** sorting with format */
  sort?: `${string}|${"asc" | "desc"}`;
  /** table limit per page */
  limit?: number;
  /** table active page */
  page?: number;
};

const prepareToQueryParams: PrepareToQueryParams = (config) => ({
  limit: config.limit,
  page: config.page,
  sort: config.sort?.key ? `${config.sort.key}|${config.sort.isAsc ? "asc" : "desc"}` : undefined,
});

const useDataTable = <T, P = Record<string, string | number>>(
  /** axios config request */
  rawRequest: Omit<AxiosRequestConfig, "baseURL" | "method" | "data"> | null,
  /** axios instance */
  fetcher: AxiosInstance,
  /** options */
  { defaultConfig, defaultParams, debounceTimeout = 300 }: DataTableOptions<P> = {},
  /** swr options */
  swrConfiguration?: SWRConfiguration,
) => {
  const [config, setConfig] = useState<TableConfig>({
    sort: defaultConfig?.sort ?? undefined,
    limit: defaultConfig?.limit ?? 10,
    page: defaultConfig?.page ?? 1,
  });
  const [params, changeParams] = useState<P>(defaultParams ?? ({} as P));
  const [debouncedState, setDebouncedState] = useState({
    ...prepareToQueryParams(config),
    ...params,
  });

  const setParams = useCallback((key: keyof P, value) => {
    changeParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setConfig((s) => ({
      ...s,
      page: 1,
    }));
  }, []);

  useDebounce(
    () => {
      setDebouncedState({
        ...prepareToQueryParams(config),
        ...params,
      });
    },
    debounceTimeout,
    [config, params],
  );

  const getRequest = useCallback(
    (
      /** get request with desired page */
      page?: number,
    ) => ({
      ...rawRequest,
      params: {
        ...(rawRequest ? rawRequest.params : null),
        ...debouncedState,
        ...(page ? { page } : {}),
      },
    }),
    [rawRequest, debouncedState],
  );
  const request = getRequest();

  const { data, isValidating, mutate } = useSWRAxios<T>(request, fetcher, swrConfiguration);

  return {
    config,
    setConfig,
    params,
    setParams,
    request,
    getRequest,
    data,
    isValidating,
    mutate,
  };
};

export default useDataTable;
