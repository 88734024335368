import { saveAs } from "file-saver";
import Button from "../../../../components/Button/Button";
import ExportIcon from "../../../../components/Icons/Stockholm/General/ExportIcon";
import { toast } from "react-toastify";
import { nfcAxios, nfcUrls } from "../../queries/nfcService";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";

type ExportQRCodeButtonProps = {
  onSuccess: () => void;
  params?: Record<string, string | number>;
};

const ExportQRCodeButton = ({ onSuccess, params }: ExportQRCodeButtonProps): JSX.Element => {
  const [state, doExport] = useAxiosAsync(async () => {
    try {
      const res = await nfcAxios.get(nfcUrls.exportQRCodes, {
        responseType: "blob",
        params,
      });
      saveAs(res.data, "qrcodes.zip");
      toast.success("Export QR Code Success");
      onSuccess();
      return res;
    } catch (error) {
      toast.error("Failed to Export QR Code");
      throw error;
    }
  }, [params]);

  return (
    <Button
      className="btn btn-light-primary me-3"
      onClick={doExport}
      loading={state.loading}
      icon={<ExportIcon size="2" />}
    >
      Export QR Code
    </Button>
  );
};

export default ExportQRCodeButton;
