import { Control, useForm, UseFormSetValue, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MuiTextField from "../../components/ControlledForm/MuiTextField";
import { AxiosPromise } from "axios";
import ControlledMuiCheckbox from "../../components/ControlledForm/MuiCheckbox";
import UncontrolledMuiCheckbox from "../../components/UncontrolledForm/MuiCheckbox";
import { useCallback, useState } from "react";
import ArrowToDownIcon from "../../components/Icons/Stockholm/Navigation/ArrowToDownIcon";
import Collapse from "../../components/Transitions/Collapse/Collapse";
import Button from "../../components/Button/Button";
import clsx from "clsx";
import useAccessGroupQuery, {
  AccessGroupType,
} from "../../hooks/queries/access/useAccessGroupQuery";

const schema = yup.object().shape({
  roleName: yup.string().required(),
});

export type RoleFormSubmittedValues = {
  roleName: string;
  access: string[];
};

type RoleFormValues = {
  roleName: string;
  access: {
    [key: string]: boolean;
  };
};

type RoleFormProps = {
  /** form id to submit when submit button clicked */
  formId: string;
  /** form on submit */
  onSubmit: (values: RoleFormSubmittedValues) => AxiosPromise | Promise<void>;
  /** form default values */
  defaultValues: RoleFormSubmittedValues;
};

const RoleForm = ({ onSubmit, defaultValues, formId }: RoleFormProps): JSX.Element => {
  const { handleSubmit, control, setValue } = useForm<RoleFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      roleName: defaultValues.roleName,
      access: defaultValues?.access?.reduce(
        (a, b) => ({
          ...a,
          [b.replaceAll(".", "_")]: true,
        }),
        {},
      ),
    },
  });

  const transformValues = useCallback(
    (values: RoleFormSubmittedValues) => {
      const data: RoleFormSubmittedValues = {
        ...values,
        access: [],
      };
      for (const property in values.access) {
        if (values.access[property]) data.access.push(property.replaceAll("_", "."));
      }
      onSubmit(data);
    },
    [onSubmit],
  );

  const { data } = useAccessGroupQuery();

  return (
    <form id={formId} onSubmit={handleSubmit(transformValues)} noValidate>
      <div className="mb-7 mt-n3">
        <MuiTextField
          control={control}
          label="Role Name"
          name="roleName"
          placeholder="e.g User"
          defaultValue={defaultValues.roleName ?? ""}
        />
      </div>
      <div>
        <div className="fw-bolder fs-5 mb-5">Access & Permissions</div>
        {data &&
          data.map((x, i) => (
            <AccessGroup key={i} control={control} setValue={setValue} group={x} />
          ))}
      </div>
    </form>
  );
};

type AccessGroupProps = {
  group: AccessGroupType;
  control: Control<RoleFormValues>;
  setValue: UseFormSetValue<RoleFormValues>;
};

const AccessGroup = ({ group, control, setValue }: AccessGroupProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const items = group.data;

  const handleParentOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      items.forEach((a) => {
        setValue(`access.${a.accessCode.replaceAll(".", "_")}`, checked);
      });
    },
    [setValue, items],
  );

  const watched = useWatch({
    control,
    name: items.map(
      (a) => `access.${a.accessCode.replaceAll(".", "_")}`,
    ) as unknown as `access.${string}`[],
  });
  const isAllChecked = watched.every((x) => x);
  const isAllUnchecked = watched.every((x) => !x);

  return (
    <div className="bg-light px-5 py-2 rounded-2 mb-7">
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <UncontrolledMuiCheckbox
            indeterminate={!isAllChecked && !isAllUnchecked}
            checked={isAllChecked}
            label={<span className="fw-bolder text-capitalize">{group.group}</span>}
            onChange={handleParentOnChange}
            formControlProps={{
              margin: "none",
            }}
          />
        </div>
        <Button
          type="button"
          className={clsx("btn-icon bg-hover-light-dark btn-sm rounded-circle", open && "active")}
          icon={<ArrowToDownIcon size="2" className="rotate-180" />}
          onClick={() => setOpen((s) => !s)}
        />
      </div>
      <Collapse in={open}>
        <div>
          {items.map((a) => (
            <div className="ms-10" key={a.accessCode.replaceAll(".", "_")}>
              <ControlledMuiCheckbox
                control={control}
                name={`access.${a.accessCode.replaceAll(".", "_")}`}
                defaultValue={false as boolean & string}
                label={a.accessDescription}
                value={a.accessCode.replaceAll(".", "_")}
                formControlProps={{
                  margin: "none",
                }}
              />
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default RoleForm;
