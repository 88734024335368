import clsx from "clsx";

type NoAccessProps = {
  label?: string;
} & React.ComponentPropsWithoutRef<"div">;

const NoAccess = ({ label, className, ...rest }: NoAccessProps): JSX.Element => {
  return (
    <div className={clsx("alert alert-warning text-center my-3", className)} {...rest}>
      You have no permissions to access <b>{label ?? "this feature"}</b>
    </div>
  );
};

export default NoAccess;
