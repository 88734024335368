import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import useConnectedNFCQuery from "../../../NFC/queries/useConnectedNFCQuery";

const UserDetailConnectedNFC = (): JSX.Element => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const { data } = useConnectedNFCQuery(id);
  const nfcs = data?.data ?? [];

  return (
    <div>
      <div className="d-flex justify-content-between mb-3 mt-7">
        <div className="fw-bolder fs-4">Connected NFCs ({nfcs.length})</div>
        <a href="#">View History</a>
      </div>
      <div className="row gy-3">
        {nfcs.map((x) => (
          <div key={x.id} className="col-12">
            <div className="p-5 border rounded-3 bg-white">
              <div className="d-flex align-items-center">
                <div className="me-5">
                  <img src={x.product?.picture} width="60" alt="" />
                </div>
                <div>
                  <Link
                    to={`/nfc/detail/${x.id}`}
                    className="fs-6 fw-bolder text-gray-800 text-hover-primary"
                  >
                    {x.product?.name}
                  </Link>
                  <div className="text-muted">Type: {x.product?.type}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserDetailConnectedNFC;
