import { Helmet } from "react-helmet-async";
import { Switch, useRouteMatch } from "react-router-dom";
import TitledRoute from "../../components/Route/TitledRoute";
import RoleList from "./RoleList";

const RolePage = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <>
      <Helmet defaultTitle="Roles | Biobio" titleTemplate="%s - Roles | Biobio" />
      <Switch>
        <TitledRoute title="Role List" path={path}>
          <RoleList />
        </TitledRoute>
      </Switch>
    </>
  );
};

export default RolePage;
