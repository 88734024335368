import StockholmIcon, { StockholmIconProps } from "../StockholmIcon";

const VisibleOffIcon = (props: StockholmIconProps): JSX.Element => {
  return (
    <StockholmIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 6.5C14.76 6.5 17 8.74 17 11.5C17 12.01 16.9 12.5 16.76 12.96L19.82 16.02C21.21 14.79 22.31 13.25 23 11.49C21.27 7.11 17 4 12 4C10.73 4 9.51 4.2 8.36 4.57L10.53 6.74C11 6.6 11.49 6.5 12 6.5ZM2.71 3.16C2.32 3.55 2.32 4.18 2.71 4.57L4.68 6.54C3.06 7.83 1.77 9.53 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.97 18.7 16.31 18.18L19.03 20.9C19.42 21.29 20.05 21.29 20.44 20.9C20.83 20.51 20.83 19.88 20.44 19.49L4.13 3.16C3.74 2.77 3.1 2.77 2.71 3.16ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 10.73 7.18 10 7.49 9.36L9.06 10.93C9.03 11.11 9 11.3 9 11.5C9 13.16 10.34 14.5 12 14.5C12.2 14.5 12.38 14.47 12.57 14.43L14.14 16C13.49 16.32 12.77 16.5 12 16.5ZM14.97 11.17C14.82 9.77 13.72 8.68 12.33 8.53L14.97 11.17Z"
          fill="#444444"
        />
      </svg>
    </StockholmIcon>
  );
};

export default VisibleOffIcon;
