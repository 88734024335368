export default function jsonToFormData(
  inJSON: Record<string, unknown>,
  inFormData?: FormData,
  parentKey?: string,
): FormData {
  // http://stackoverflow.com/a/22783314/260665
  // Raj: Converts any nested JSON to formData.
  const formData = inFormData || new FormData();
  for (const key in inJSON) {
    // 1. If it is a recursion, then key has to be constructed like "parent[child]" where parent JSON contains a child JSON
    // 2. Perform append data only if the value for key is not a JSON, recurse otherwise!
    let constructedKey = key;
    if (parentKey) {
      constructedKey = `${parentKey}[${key}]`;
      // constructedKey = parentKey + "[" + key + "]";
    }

    const value = inJSON[key];
    if (
      value &&
      typeof value === "object" &&
      value !== null &&
      value.constructor === {}.constructor
    ) {
      // This is a JSON, we now need to recurse!
      jsonToFormData(value as Record<string, unknown>, formData, constructedKey);
    } else if (typeof value !== "undefined") {
      formData.append(constructedKey, inJSON[key] as string);
    }
  }
  return formData;
}
