import { SWRConfiguration } from "swr";
import { useSWRAxios } from "../../../hooks/useSWRAxios";
import { userAxios, userUrls } from "./userService";

export type LoggedInUserAdditionalDataType = {
  access: string[];
};

export type UserType = {
  id: number;
  name: string;
  username: string;
  email: string;
  bio: string;
  picture: string;
  location: string;
  isBlocked: boolean;
  isPrivate: boolean;
  isPro: boolean;
  isContactPrivate: boolean;
  isVerified: boolean;
  userContentOrders: number[];
  lastActive: string;
  lastDevice: string;
  created_at: string;
  updated_at: string;
};

const useUserQuery = (idUsersProfile?: string, swrConfig?: SWRConfiguration) =>
  useSWRAxios<UserType>(
    idUsersProfile
      ? {
          url: userUrls.detail(idUsersProfile),
          method: "GET",
        }
      : null,
    userAxios,
    swrConfig,
  );

export default useUserQuery;
