import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import useAxiosAsync from "../../../../hooks/useAxiosAsync";
import ThemesAutoComplete from "../../../Themes/utilities/ThemesAutoComplete";
import { nfcBulkUpdateTheme } from "../../queries/nfcService";
import { NFCType } from "../../queries/useNFCQuery";

type UpdateBulkNFCThemeProps = {
  items: NFCType[];
  onSuccess: () => void;
};

const UpdateBulkNFCTheme = ({ items, onSuccess }: UpdateBulkNFCThemeProps): JSX.Element => {
  const [selectedId, setSelectedId] = useState("");

  const [state, submit] = useAxiosAsync(
    async (themeId: string, items: NFCType[]) => {
      try {
        const data = {
          nfcs: items.map((x) => x.id),
        };
        const response = await nfcBulkUpdateTheme(themeId, data);
        toast.success(`${items.length} NFC(s) Updated`);
        onSuccess();
        return response;
      } catch (error) {
        toast.error(`Failed to Update ${items.length} NFC(s)`);
        throw error;
      }
    },
    [onSuccess],
  );

  return (
    <div className="d-flex">
      <div className="w-200px me-3">
        <ThemesAutoComplete
          placeholder="Choose Theme"
          onChange={(_e, v) => setSelectedId(v.id.toString())}
          textFieldProps={{
            hiddenLabel: true,
            margin: "none",
          }}
          disableClearable
        />
      </div>
      <div>
        <Button
          variant="base-primary"
          disabled={!selectedId}
          loading={state.loading}
          onClick={() => submit(selectedId, items)}
        >
          Update NFC
        </Button>
      </div>
    </div>
  );
};

export default UpdateBulkNFCTheme;
