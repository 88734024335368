import clsx from "clsx";
import React from "react";
import { useCallback } from "react";
import { match, Route, useHistory, useRouteMatch } from "react-router-dom";
import Drawer from "./Drawer";

type PropsAreEqual<T> = (prevProps: Readonly<T>, nextProps: Readonly<T>) => boolean;

const drawerSize = {
  small: "w-300px",
  medium: "w-500px",
  large: "w-750px",
};

export interface WithRouteDrawerProps<T = Record<string, unknown>> {
  onClose: () => void;
  match: match<T> | null;
  path: string;
  size?: keyof typeof drawerSize;
}

const withRouteDrawer = <T extends Partial<WithRouteDrawerProps>>(
  Component: {
    (props: T): JSX.Element;
    displayName?: string;
  },
  propsAreEqual?: PropsAreEqual<T> | false,

  componentName = Component.displayName ?? Component.name,
): {
  (props: Partial<T>): JSX.Element;
  displayName: string;
} => {
  function WithRouteDrawer({ onClose, path = "/detail", size = "medium", ...props }: Partial<T>) {
    const { push } = useHistory();
    const { path: pathPrefix } = useRouteMatch();

    const handleClose = useCallback(() => {
      push(pathPrefix);
      onClose?.();
    }, [push, onClose, pathPrefix]);

    return (
      <Route path={pathPrefix + path}>
        {({ match }) => (
          <Drawer
            position="end"
            in={!!match}
            onClose={handleClose}
            className={clsx("mw-100", drawerSize[size])}
          >
            <Component {...(props as T)} path={path} onClose={handleClose} match={match} />
          </Drawer>
        )}
      </Route>
    );
  }

  WithRouteDrawer.displayName = `withRouteDrawer(${componentName})`;

  const wrappedComponent =
    propsAreEqual === false ? WithRouteDrawer : React.memo(WithRouteDrawer, propsAreEqual);

  return wrappedComponent as typeof WithRouteDrawer;
};

export default withRouteDrawer;
