import clsx from "clsx";
import { ReactElement, SVGProps } from "react";

type IconColors =
  | "white"
  | "primary"
  | "secondary"
  | "light"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "dark"
  | "muted"
  | "gray-100"
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-700"
  | "gray-800"
  | "gray-900"
  | "inherit";

type IconSizes =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "7"
  | "8"
  | "9"
  | "10"
  | "base"
  | "fluid"
  | "2x"
  | "2qx"
  | "2hx"
  | "2tx"
  | "3x"
  | "3qx"
  | "3hx"
  | "3tx"
  | "4x"
  | "4qx"
  | "4hx"
  | "4tx"
  | "5x"
  | "5qx"
  | "5hx"
  | "5tx";

interface StockholmIconContainerProps {
  /** Icon SVG */
  children?: ReactElement;
  /** Color */
  color?: IconColors;
  /** Size */
  size?: IconSizes;
  /** class list */
  className?: string;
}

const StockholmIcon = ({
  children,
  color,
  size,
  className,
  ...rest
}: StockholmIconContainerProps): JSX.Element => {
  return (
    <span
      className={clsx(
        "svg-icon",
        color && `svg-icon-${color}`,
        size && `svg-icon-${size}`,
        className,
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

export type StockholmIconProps = Omit<StockholmIconContainerProps, "children"> &
  Partial<SVGProps<SVGElement>>;

export default StockholmIcon;
