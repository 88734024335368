import { Divider, Tooltip } from "@mui/material";
import DuplicateIcon from "../Icons/Stockholm/General/DuplicateIcon";
import Button from "../Button/Button";
import { useCallback } from "react";
import copyTextToClipboard from "../../../utils/copyTextToClipboard";
import { toast } from "react-toastify";

type CopyTooltipProps = {
  url: string;
} & React.ComponentPropsWithoutRef<"div">;

const CopyTooltip = ({ url, children }: CopyTooltipProps): JSX.Element => {
  const copy = useCallback(() => {
    copyTextToClipboard(
      url,
      () => {
        toast.info("Copied to Clipboard");
      },
      () => {
        toast.error("Failed to copy to Clipboard");
      },
    );
  }, [url, copyTextToClipboard]);

  return (
    <Tooltip
      title={
        <div className="d-flex align-items-center py-1">
          <a href={url} target="_blank" rel="noreferrer noopener" className="fs-6 text-white">
            {url}
          </a>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ height: "auto !important", margin: "0 10px" }}
          />
          <Button className="btn-icon btn-sm" onClick={copy}>
            <DuplicateIcon size="1" color="white" />
          </Button>
        </div>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default CopyTooltip;
