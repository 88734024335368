import clsx from "clsx";

const buttonVariant = {
  "base-white": "btn-white",
  "base-primary": "btn-primary",
  "base-light": "btn-light",
  "base-secondary": "btn-secondary",
  "base-success": "btn-success",
  "base-info": "btn-info",
  "base-warning": "btn-warning",
  "base-danger": "btn-danger",
  "base-dark": "btn-dark",
  "light-primary": "btn-light-primary",
  "light-success": "btn-light-success",
  "light-info": "btn-light-info",
  "light-warning": "btn-light-warning",
  "light-danger": "btn-light-danger",
  "light-dark": "btn-light-dark",
  "bg-white": "btn-bg-white",
  "bg-primary": "btn-bg-primary",
  "bg-light": "btn-bg-light",
  "bg-secondary": "btn-bg-secondary",
  "bg-success": "btn-bg-success",
  "bg-info": "btn-bg-info",
  "bg-warning": "btn-bg-warning",
  "bg-danger": "btn-bg-danger",
  "bg-dark": "btn-bg-dark",
  "outline-default": "btn-outline btn-outline-default",
  "outline-primary": "btn-outline btn-outline-primary btn-active-light-primary",
  "outline-success": "btn-outline btn-outline-success btn-active-light-success",
  "outline-info": "btn-outline btn-outline-info btn-active-light-info",
  "outline-warning": "btn-outline btn-outline-warning btn-active-light-warning",
  "outline-danger": "btn-outline btn-outline-danger btn-active-light-danger",
  "outline-dark": "btn-outline btn-outline-dark btn-active-light-dark",
};

export type ButtonProps = {
  icon?: JSX.Element;
  loading?: boolean;
  variant?: keyof typeof buttonVariant;
} & React.ComponentPropsWithoutRef<"button">;

const Button = ({
  className,
  children,
  variant,
  icon,
  loading,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button
      disabled={loading}
      {...props}
      className={clsx("btn", className, variant && buttonVariant[variant])}
    >
      {loading ? (
        <span
          className={clsx("spinner-border spinner-border-sm align-middle", children && "me-2")}
        />
      ) : (
        icon
      )}
      {children}
    </button>
  );
};

export default Button;
